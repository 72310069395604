<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-form @submit.prevent="createMassiveSender">
      <v-card prepend-icon="mdi-bullseye-arrow" title="Envio de mensagem em massa">
        <v-container>
          <v-row>
            <v-radio-group inline v-model="senderData.executionFlag">
              <v-radio label="Enviar Agora" value="now"></v-radio>
              <v-radio label="Agendar envio" value="scheduled"></v-radio>
            </v-radio-group>
            <v-btn @click="downloadTemplate" prepend-icon="mdi-file-download" class="m-1">
              Baixar Planilha Modelo
            </v-btn>
          </v-row>
          <v-row v-if="senderData.executionFlag === 'scheduled'">
            <v-select
              label="Escolha o Dia"
              :items="dateOptions"
              v-model="executionDate"
              :rules="dateRules"
              required
            ></v-select>
            <v-select
              label="Escolha a Hora"
              :items="timeOptions"
              v-model="executionTime"
              :rules="timeRules"
              required
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              label="Tipo de mensagem"
              :items="messageTypes"
              v-model="senderData.messageType"
            ></v-select>
          </v-row>
          <v-row>
            <v-textarea
              v-if="senderData.messageType === 'text'"
              placeholder="Aqui está a mensagem que será enviada para os números."
              v-model="senderData.messageContent"
              required
            ></v-textarea>
          </v-row>
          <v-row>
            <v-file-input
              v-if="senderData.messageType === 'img'"
              :rules="rulesIMG"
              accept="image/png, image/jpeg, image/bmp"
              label="Selecione a imagem para enviar"
              v-model="selectedMediaFile"
            ></v-file-input>
          </v-row>
          <v-row>
            <v-file-input
              v-if="senderData.messageType === 'audio'"
              accept="audio/mpeg, audio/wav, audio/ogg, audio/aac"
              label="Selecione o audio para enviar"
              v-model="selectedMediaFile"
            ></v-file-input>
          </v-row>
          <v-row>
            <v-file-input
              v-if="senderData.messageType === 'pdf'"
              accept="application/pdf"
              label="Selecione o PDF para enviar"
              v-model="selectedMediaFile"
            ></v-file-input>
          </v-row>

          <v-row>
            <v-file-input
              ref="fileInput"
              accept=".xlsx, .xls"
              label="Enviar Planilha de Contatos"
              prepend-icon="mdi-file-upload"
              @change="handleFileUpload"
            >
            </v-file-input>
          </v-row>

          <v-row>
            <v-textarea
              v-model="senderData.phoneNumbers"
              placeholder="Números para enviar a mensagem, o formato dos números deve ser 5521999999999, onde 55 é o código do país, 21 é o DDD sem o zero, seguindo do número de telefone, cada número deve estar separado por uma vírgula."
              :rules="messageRules"
              required
            ></v-textarea>
          </v-row>
          <v-row>
            <small class="text-muted">Exemplo: 5521999999999,551199999999</small>
          </v-row>

          <v-row justify="center">
            <v-col cols="auto">
              <v-btn text="Enviar" type="submit"></v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn class="ml-auto" text="Fechar" @click="dialog = false"></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { apiClient } from '@/api/ServiceApi'
import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs'
import { useToast } from 'vue-toast-notification'

// Definindo as propriedades do componente
const props = defineProps<{
  userData: {
    id: string
    name: string
  } | null
  reuseData: {
    contacts: string
    content: string
  } | null
}>()
const dialog = ref(false)
// Definindo regras de validação
const rulesIMG = [
  (value: FileList | null) => {
    return (
      !value || !value.length || value[0].size < 5000000 || 'Imagem muito grande mais que 5 Mb!'
    )
  }
]
const timeRules = [
  (value: string) => {
    if (value) return true
    return 'Você precisa selecionar uma hora'
  }
]

const dateRules = [
  (value: string) => {
    if (value) return true
    return 'Você precisa selecionar uma data'
  }
]

const messageRules = [
  (value: string) => {
    if (value) return true
    return 'Você precisa inserir contatos'
  }
]

// Definindo estado reativo

// Definindo itens para o v-select
const messageTypes = [
  { title: 'Texto', value: 'text' },
  { title: 'Imagem', value: 'img' },
  { title: 'PDF', value: 'pdf' },
  { title: 'Áudio', value: 'audio' }
]

// Referência para o elemento de entrada de arquivo
const fileInput = ref<HTMLInputElement | null>(null)

const invalidDate = ref(false)
const errMessage = ref('')
interface senderDataInterface {
  customerId: string
  messageContent: string
  messageType: string
  phoneNumbers: string
  executionFlag: 'now' | 'scheduled'
  executionTime?: string
  messageMedia?: string
}
const $toast = useToast()
const senderData = ref<senderDataInterface>({
  customerId: props.userData?.id || '',
  messageContent: '',
  messageType: 'text',
  phoneNumbers: '',
  executionFlag: 'now',
  executionTime: '',
  messageMedia: ''
})
const selectedMediaFile = ref<File | null>(null) // Nova variável reativa para manter o arquivo selecionado

const executionDate = ref('')
const executionTime = ref('')
watch(
  () => props.reuseData,
  (newValue) => {
    if (newValue) {
      senderData.value.messageContent = newValue.content
      senderData.value.phoneNumbers = newValue.contacts
    }
  }
)

watch([executionDate, executionTime], () => {
  senderData.value.executionTime = `${executionDate.value}T${executionTime.value}:00.000Z`
})

// Função para converter phoneNumbers para a lista formatada com @c.us
function convertPhoneNumbersWithCus(senderData: any) {
  // Verificar se phoneNumbers é uma string e se não está vazia
  if (
    typeof senderData.value.phoneNumbers === 'string' &&
    senderData.value.phoneNumbers.trim() !== ''
  ) {
    // Remover todos os caracteres que não sejam números e separar por vírgula
    senderData.value.phoneNumbers = senderData.value.phoneNumbers
      .split(',')
      .map((number: string) => number.replace(/\D/g, '').trim())
      .filter((number: string) => number !== '') // Remove qualquer entrada vazia

    // Mapear cada número e formatar com @c.us
    senderData.value.phoneNumbers = senderData.value.phoneNumbers.map(
      (number: string) => `${number}@c.us`
    )
  }
}

function isFutureDateTime(date: string, time: string) {
  // Combine date and time into uma única string datetime em formato UTC
  const datetimeString = `${date}T${time}:00Z`

  // Crie um objeto Date baseado na string datetime em formato UTC
  const executionDateTime = new Date(datetimeString)

  // Obtenha o timestamp atual em UTC
  const now = new Date()

  // Log para depuração
  // console.log('Execution Date Time:', executionDateTime.toISOString())
  // console.log('Now:', now.toISOString())

  // Verifique se o tempo de execução está no futuro em relação ao tempo atual
  return executionDateTime.getTime() > now.getTime()
}

const createMassiveSender = async () => {
  // Verifica se o campo de números de telefone está vazio
  if (!senderData.value.phoneNumbers.trim()) {
    $toast.warning('Por favor, insira ao menos um número de telefone.', {
      duration: 5000,
      position: 'top-right'
    })
    return // Retorna sem executar o restante da função
  }
  if (senderData.value.executionFlag === 'scheduled') {
    if (!executionDate.value || !executionTime.value) {
      $toast.warning('Data e hora de execução são necessárias para tarefas agendadas.', {
        duration: 5000,
        position: 'top-right'
      })
      return
    }
    if (!isFutureDateTime(executionDate.value, executionTime.value)) {
      $toast.warning('A data e hora de execução devem ser no futuro.', {
        duration: 5000,
        position: 'top-right'
      })
      invalidDate.value = true
      return
    }
    invalidDate.value = false
    senderData.value.executionTime = `${executionDate.value}T${executionTime.value}:00.000Z`
  } else {
    delete senderData.value.executionTime
  }

  // Função para converter phoneNumbers para a lista formatada com @c.us
  convertPhoneNumbersWithCus(senderData)
  // Se o tipo de mídia não for 'text', faça o upload do arquivo de mídia
  if (senderData.value.messageType !== 'text') {
    // Verifica se o arquivo de mídia está disponível no senderData.messageMedia
    if (selectedMediaFile.value instanceof File && selectedMediaFile.value.size > 0) {
      const formData = new FormData()
      formData.append('file', selectedMediaFile.value)
      formData.append('userId', 'ms-' + props.userData?.id || 'broken-send')
      try {
        const sendMedia = await apiClient.post('/upload/media', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        senderData.value.messageMedia = sendMedia.data.filename
      } catch (error) {
        $toast.error('Falha ao enviar mídia!', { duration: 5000, position: 'top-right' })
        return
      }
    } else {
      $toast.error('Nenhuma arquivo foi selecionado !', { duration: 5000, position: 'top-right' })
      return
    }
  }
  try {
    const response = await apiClient.post('/tasks/massivesendmessage', senderData.value)
    if (response.status === 201) {
      $toast.success('Tarefa de envio criada com sucesso !', {
        duration: 5000,
        position: 'top-right'
      })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao criar tarefa de envio !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Falha ao criar tarefa de envio !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}

const downloadTemplate = async () => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Contatos')

  // Define a validação de dados para lista suspensa
  const validationWpp = {
    type: 'list' as const, // Define explicitamente como 'list'
    formulae: ['"Enviado,Pendente,Erro"'], // Itens da lista suspensa separados por vírgulas
    showErrorMessage: true,
    errorTitle: 'Entrada inválida',
    error: 'Selecione um elemento da lista'
  }

  // Aplica a validação de dados ao intervalo de células
  for (let i = 2; i <= 999; i++) {
    worksheet.getCell(`C${i}`).dataValidation = validationWpp
  }

  // Define a validação de dados para lista suspensa
  const validationCall = {
    type: 'list' as const, // Define explicitamente como 'list'
    formulae: ['"Atendido,Agendado,Não atendido"'], // Itens da lista suspensa separados por vírgulas
    showErrorMessage: true,
    errorTitle: 'Entrada inválida',
    error: 'Selecione um elemento da lista'
  }

  // Aplica a validação de dados ao intervalo de células
  for (let i = 2; i <= 999; i++) {
    worksheet.getCell(`D${i}`).dataValidation = validationCall
  }

  // Define a validação de dados para lista suspensa
  const validationMeet = {
    type: 'list' as const, // Define explicitamente como 'list'
    formulae: ['"Concluída,Agendada,Reagendar,Cancelada"'], // Itens da lista suspensa separados por vírgulas
    showErrorMessage: true,
    errorTitle: 'Entrada inválida',
    error: 'Selecione um elemento da lista'
  }

  // Aplica a validação de dados ao intervalo de células
  for (let i = 2; i <= 999; i++) {
    worksheet.getCell(`E${i}`).dataValidation = validationMeet
  }

  // Define a validação de dados para lista suspensa
  const validationProposal = {
    type: 'list' as const, // Define explicitamente como 'list'
    formulae: ['"Aceita,Apresentação,Ajustes,Aguardando resposta,Declinado"'], // Itens da lista suspensa separados por vírgulas
    showErrorMessage: true,
    errorTitle: 'Entrada inválida',
    error: 'Selecione um elemento da lista'
  }

  // Aplica a validação de dados ao intervalo de células
  for (let i = 2; i <= 999; i++) {
    worksheet.getCell(`F${i}`).dataValidation = validationProposal
  }

  // Define a validação de dados para lista suspensa
  const validationPayment = {
    type: 'list' as const, // Define explicitamente como 'list'
    formulae: ['"Concluído,Agendado,Atrasado,Cancelado"'], // Itens da lista suspensa separados por vírgulas
    showErrorMessage: true,
    errorTitle: 'Entrada inválida',
    error: 'Selecione um elemento da lista'
  }

  // Aplica a validação de dados ao intervalo de células
  for (let i = 2; i <= 999; i++) {
    worksheet.getCell(`G${i}`).dataValidation = validationPayment
  }

  // Define a validação de dados para lista suspensa
  const validationBusiness = {
    type: 'list' as const, // Define explicitamente como 'list'
    formulae: ['"Negócio Fechado,Declinado"'], // Itens da lista suspensa separados por vírgulas
    showErrorMessage: true,
    errorTitle: 'Entrada inválida',
    error: 'Selecione um elemento da lista'
  }

  // Aplica a validação de dados ao intervalo de células
  for (let i = 2; i <= 999; i++) {
    worksheet.getCell(`H${i}`).dataValidation = validationBusiness
  }

  // Adiciona regras de formatação condicional para colorir com base na seleção
  worksheet.addConditionalFormatting({
    ref: 'H2:H999',
    rules: [
      {
        type: 'expression',
        formulae: ['H2="Negócio Fechado"'],
        priority: 1, // Defina a prioridade
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FF00' } // Verde
          },
          font: { color: { argb: 'FFFFFF' } } // Branco
        }
      },
      {
        type: 'expression',
        formulae: ['H2="Declinado"'],
        priority: 2, // Defina a prioridade
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF0000' } // Vermelho
          },
          font: { color: { argb: 'FFFFFF' } } // Branco
        }
      }
    ]
  })

  // Define a largura das colunas para visualização
  worksheet.getColumn(1).width = 50
  worksheet.getColumn(2).width = 30
  worksheet.getColumn(3).width = 20
  worksheet.getColumn(4).width = 20
  worksheet.getColumn(5).width = 20
  worksheet.getColumn(6).width = 20
  worksheet.getColumn(7).width = 20
  worksheet.getColumn(8).width = 20
  // Mesclagem de células
  worksheet.mergeCells('A1:B1')
  worksheet.getCell('A1').value = 'Dados do Negócio'
  worksheet.getCell('A1').alignment = {
    horizontal: 'center' // Centraliza horizontalmente
  }
  worksheet.mergeCells('C1:H1')
  const cell = worksheet.getCell('C1')
  cell.value = 'Cadência Comercial'
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '228B22' } // cor de fundo (Verde)
  }
  cell.font = {
    color: { argb: 'FFFFFF' } // cor do texto (Branco)
  }

  worksheet.getCell('A2').value = 'Nome'
  worksheet.getCell('B2').value = 'Telefone de contato'

  for (let i = 0; i < 2; i++) {
    // Calcula o código da letra a partir de 'A' + i
    const letter = String.fromCharCode('A'.charCodeAt(0) + i)
    console.log(letter)
    const cell = worksheet.getCell(`${letter}2`)

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'A020F0' } // cor de fundo (Roxo)
    }

    cell.font = {
      color: { argb: 'FFFFFF' } // cor do texto (Branco)
    }
  }

  worksheet.getCell('C2').value = 'Abordagem Whatsapp'
  worksheet.getCell('D2').value = 'Ligação'
  worksheet.getCell('E2').value = 'Reunião'
  worksheet.getCell('F2').value = 'Proposta'
  worksheet.getCell('G2').value = 'Pagamento'
  worksheet.getCell('H2').value = 'Negócio'
  for (let i = 2; i <= 8; i++) {
    // C é a 3ª letra, H é a 8ª letra (1-indexado)
    const letter = String.fromCharCode('A'.charCodeAt(0) + i - 1) // Converte 2 a 7 para letras C a H
    const cell = worksheet.getCell(`${letter}2`)

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '228B22' } // cor de fundo (Verde)
    }

    cell.font = {
      color: { argb: 'FFFFFF' } // cor do texto (Branco)
    }
  }

  // Salva o arquivo
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  const url = URL.createObjectURL(blob)

  // Cria um link para download e clica nele para baixar o arquivo
  const a = document.createElement('a')
  a.href = url
  a.download = 'modelo_contatos_botex_sender.xlsx'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}

// Função para manipular o upload do arquivo
const handleFileUpload = (event: Event) => {
  const input = event.target as HTMLInputElement
  if (input.files && input.files[0]) {
    const file = input.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer)
      const workbook = XLSX.read(data, { type: 'array' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      // Converter a planilha em JSON e extrair números de telefone da coluna B (índice 1)
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      const phoneNumbers = jsonData
        .slice(1) // Ignora o cabeçalho
        .map((row: any) => row[1]?.toString().trim()) // Acessa a segunda coluna (B)
        .filter((num: string) => num && !isNaN(Number(num))) // Verifica se é um número válido
        .join(',')

      senderData.value.phoneNumbers = phoneNumbers
      $toast.success('Arquivo carregado com sucesso!', {
        duration: 3000,
        position: 'top-right'
      })
    }

    reader.readAsArrayBuffer(file)

    // Redefine o valor do input para permitir o reupload do mesmo arquivo
    input.value = ''
  }
}


const generateTimeOptions = (timezone: string) => {
  const times = []

  // Definir limites para horas que podem ser exibidas no fuso horário local
  const minLocalHour = 7
  const maxLocalHour = 19

  // Loop em cada hora UTC
  for (let hour = 0; hour < 24; hour++) {
    const date = new Date(Date.UTC(1970, 0, 1, hour, 0, 0)) // Data arbitrária em UTC

    // Formata a data para o fuso horário especificado
    const displayTime = new Intl.DateTimeFormat('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: timezone
    }).format(date)

    // Extrai a hora local como número inteiro
    const localHour = parseInt(displayTime.substring(0, 2))

    // Filtra para exibir apenas as horas entre 7 e 19 no horário local
    if (localHour >= minLocalHour && localHour <= maxLocalHour) {
      const utcTime = date.toISOString().substring(11, 16) // Hora em UTC
      times.push({ value: utcTime, title: displayTime })
    }
  }
  return times
}

// Função para gerar datas limitadas a 15 dias a partir de hoje
const dateOptions = (() => {
  const dates = []
  const today = new Date()
  const daysAheadLimit = 15

  for (let i = 0; i <= daysAheadLimit; i++) {
    const date = new Date(today)
    date.setDate(today.getDate() + i)

    // Formatação de data com dia da semana, por exemplo, "Quarta, 14/08/2024"
    const formattedDate = new Intl.DateTimeFormat('pt-BR', {
      weekday: 'long', // Nome completo do dia da semana
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date)

    // Formatação de valor ISO, por exemplo, "2024-08-14"
    const valueDate = date.toISOString().split('T')[0]

    dates.push({ value: valueDate, title: formattedDate })
  }

  return dates
})()
const timeOptions = ref(generateTimeOptions('America/Sao_Paulo')) // Horários para GMT-3
</script>
