<template>
  <v-app>
    <v-footer class="sidebar-gradient" height="20px" app>
      <v-row justify="center" no-gutters>
        <v-col class="text-center">
          © {{ new Date().getFullYear() }} | <strong>bot ex_</strong> | Versão 2.0
        </v-col>
      </v-row>
    </v-footer>

    <v-app-bar flat class="border-b sidebar-gradient">
      <v-app-bar-nav-icon color="#fff" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>

      <v-app-bar-title>
        <img :src="logo" style="height: 10vh" />
      </v-app-bar-title>
      <template #append>
        <v-chip>
          {{ userData?.name }}
        </v-chip>
        <v-img :width="50" :src="avatarImage" rounded="circle" :title="userData?.name"></v-img>
      </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="isDrawerOpen" class="primary">
      <v-card :title="userData?.name" elevated hover> </v-card>

      <v-list>
        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-view-dashboard"
          :title="'Dashboard'"
          router
          to="/customer/dashboard"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>

        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-robot"
          :title="'Assistente Virtual'"
          router
          :to="`/customer/automations/${userData?.id}`"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>

        <v-list-group
          prepend-icon="mdi:mdi-bullhorn"
          value="WppMarketing"
          v-if="hasFunction('wppmarketing')"
        >
          <template v-slot:activator="{ props }">
            <v-list-item slim v-bind="props" title="WppMarketing"></v-list-item>
          </template>

          <v-list-item
            slim
            v-for="([title, icon, path], i) in wppmarketing"
            :key="i"
            router
            :to="path"
            :prepend-icon="icon"
            :title="title"
            rounded="xl"
            active-class="menu-active"
          >
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi:mdi-apps" value="crm" v-if="hasFunction('crm')">
          <template v-slot:activator="{ props }">
            <v-list-item slim v-bind="props" title="CRM"></v-list-item>
          </template>

          <v-list-item
            slim
            v-for="([title, icon, path], i) in crm"
            :key="i"
            router
            :to="path"
            :prepend-icon="icon"
            :title="title"
            rounded="xl"
            active-class="menu-active"
          >
          </v-list-item>
        </v-list-group>
        <v-list-item></v-list-item>
        <v-list-item></v-list-item>
        <v-list-item></v-list-item>
        <v-list-item></v-list-item>
        <v-divider></v-divider>
        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-information"
          title="Sobre"
          router
          to="/customer/about"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>
        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-logout"
          title="Logout"
          @click="UserLogout"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view v-slot="{ Component, route }">
        <component :is="Component" v-bind="getComponentProps(Component)" />
      </router-view>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
  VNavigationDrawer,
  VList,
  VListItem,
  VListGroup,
  VDivider,
  VApp,
  VAppBar,
  VFooter
} from 'vuetify/components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { jwtDecode } from 'jwt-decode'
import router from '@/router'

library.add(fas, faTwitter, faFontAwesome)

interface PlanData {
  features: string[]
  name: string
}

interface TokenData {
  email: string
  exp: number
  iat: number
  name: string
  sub: string
  plan: PlanData
}

const token = localStorage.getItem('token')
const tokenData = token ? jwtDecode<TokenData>(token) : null

const userData = ref<{ id: string; name: string } | null>(
  tokenData ? { id: tokenData.sub, name: tokenData.name } : null
)
const avatarImage = ref('/avatar.png')
const logo = ref('/logo.png')
async function UserLogout() {
  localStorage.removeItem('token')
  await router.push('/login')
}

function getComponentProps(Component: any) {
  if (Component.type.__name === 'CustomerDashboardView') {
    return { userData: userData.value }
  } else if (Component.type.__name === 'MarketingMassiveSender') {
    return { userData: userData.value }
  } else if (Component.type.__name === 'MultiAgentView') {
    return { userData: userData.value }
  } else if (Component.type.__name === 'AutomationsView') {
    return { userData: userData.value }
  }
  return {}
}

const hasFunction = (func: string) => {
  return tokenData?.plan.features.includes(func) ?? false
}

const isDrawerOpen = ref(true)
const wppmarketing = ref([
  ['Envio em Massa', 'mdi-account-multiple-outline', '/customer/marketing/sender']
])
const crm = ref([['Atendimento', 'mdi-account-multiple-check', '/customer/crm/chat']])
</script>

<style scoped>
.menu-active {
  color: var(--color-botex);
}
.sidebar-gradient {
  background: linear-gradient(90deg, #5170ff, #ff66c4);
  color: #fff;
}
</style>
