<script setup lang="ts">
import { apiClient } from '../api/ServiceApi'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { onBeforeMount, ref } from 'vue'
library.add(fas, faTwitter, faFontAwesome)

const allUsers = ref()

async function list_users() {
  try {
    const response = await apiClient.get('/usergroup')
    if (response['data']['statusCode'] == 200) {
      allUsers.value = response['data']['allUserGroups']
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}

onBeforeMount(async () => {
  await list_users()

  document.getElementById('deleteusermodal')?.addEventListener('show.bs.modal', (event: any) => {
    const relatedTarget = event.relatedTarget as HTMLElement | null
    const userId = relatedTarget?.dataset.userId
    const userName = relatedTarget?.dataset.userName
    const userNameField = document.getElementById('user_to_delete') as HTMLHeadingElement | null
    const userIdField = document.getElementById('user_id_to_delete') as HTMLHeadingElement | null
    // Verifique se o elemento foi encontrado
    if (
      userNameField &&
      userName !== undefined &&
      userName !== null &&
      userIdField &&
      userId !== undefined &&
      userId !== null
    ) {
      // Modifique o conteúdo do elemento
      userNameField.textContent = userName
      userIdField.textContent = userId
    }
  })
})
</script>

<template>
  <div class="overflow-auto w-100 table_div">
    <table class="table table-hover">
      <thead class="table-dark">
        <tr>
          <th scope="col" class="user-select-none">Nome</th>
          <th scope="col" class="user-select-none">Email</th>
          <th scope="col" class="user-select-none">Grupo</th>
          <th scope="col" class="user-select-none">Status</th>
          <th scope="col" class="user-select-none" style="width: 30vh">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in allUsers" v-bind:key="user.name">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>Grupo aqui</td>
          <td>
            <div v-if="user.enabled">
              <v-btn data-toggle="tooltip" data-placement="top" title="Usuário Ativo">
                <FontAwesomeIcon :icon="fas.faUser" />
              </v-btn>
            </div>
            <div v-else>
              <v-btn data-toggle="tooltip" data-placement="top" title="Usuário Inativo">
                <FontAwesomeIcon :icon="fas.faUserLargeSlash" />
              </v-btn>
            </div>
          </td>
          <td>
            <v-btn
              class="me-2"
              data-bs-toggle="modal"
              data-bs-target="#editusermodal"
              :data-user-id="user._id"
              :data-user-name="user.name"
            >
              <FontAwesomeIcon :icon="fas.faEdit" />
            </v-btn>
            <v-btn
              data-bs-toggle="modal"
              data-bs-target="#deleteusermodal"
              :data-user-id="user._id"
              :data-user-name="user.name"
            >
              <FontAwesomeIcon :icon="fas.faTrashAlt" />
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="section p-3">
    <v-btn data-bs-toggle="modal" data-bs-target="#createusermodal"> Cadastrar Usuário </v-btn>
    <!-- <CreateUser />
    <DeleteUser />
    <EditUser /> -->
  </div>
</template>

<style scoped>
.table_div {
  height: 70vh;
}
.table thead {
  position: sticky;
  top: 0;
  background-color: #b40000; /* Optional: Set a background color for the fixed header */
  z-index: 1; /* Ensure the header is on top of the content */
}
</style>
