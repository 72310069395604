<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'

const user_data = {
  name: '',
  email: '',
  password: '',
  enabled: false
}

async function create_user() {
  const $toast = useToast()
  try {
    const response = await apiClient.post(`/user`, user_data)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Usuário criado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao criar o usuário!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao criar usuário !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)

    return true
  }
}
</script>
<template>
  <div
    class="modal fade"
    id="createusermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="registerproduct">Cadastro de Usuário</h1>
          <v-btn
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <form @submit.prevent="create_user">
            <div class="mb-3">
              <label class="form-label" for="user_full_name">Nome</label>
              <input
                type="text"
                class="form-control"
                id="user_full_name"
                placeholder="Nome Completo"
                v-model="user_data.name"
              />
            </div>
            <div class="mb-3">
              <label for="user_mail" class="form-label">Email</label>
              <input
                type="text"
                class="form-control"
                id="user_mail"
                placeholder="Email"
                v-model="user_data.email"
              />
            </div>
            <div class="mb-3">
              <label for="user_password" class="form-label">Senha</label>
              <input
                type="password"
                class="form-control"
                id="user_password"
                placeholder="Senha"
                v-model="user_data.password"
              />
            </div>
            <div class="mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="user_status"
                v-model="user_data.enabled"
                checked
              />
              <label class="form-check-label" for="user_status"> Habilitar usuário </label>
            </div>

            <div class="modal-footer">
              <v-btn type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Fechar
              </v-btn>
              <v-btn type="submit" class="btn btn-primary">Cadastrar</v-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn,
input,
option,
select,
div {
  border-radius: 30px;
}
</style>
