<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { ref, watch } from 'vue'
import { apiClient } from '@/api/ServiceApi'

const props = defineProps<{
  planData: { id: string; name: string; features: string[]; value: string; enabled: string } | null
}>()

const planID = ref('')
const planName = ref('')
const planFeatures = ref([''])
const planValue = ref('')
const planEnabled = ref('')
const $toast = useToast()

watch(
  () => props.planData,
  (newValue) => {
    if (newValue) {
      planID.value = newValue.id
      planName.value = newValue.name
      planFeatures.value = newValue.features
      planValue.value = newValue.value
      planEnabled.value = newValue.enabled
    }
  }
)
function maskMoney() {
  let v = planValue.value.replace(/\D/g, '') // Remove tudo que não for dígito

  if (v.length === 0) {
    planValue.value = ''
    return
  }

  v = (parseInt(v) / 100).toFixed(2) + '' // Divide por 100 e fixa duas casas decimais
  v = v.replace('.', ',') // Substitui ponto por vírgula para a separação de centavos
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') // Coloca pontos a cada 3 dígitos

  planValue.value = v
}
const features = ref([
  { id: 1, label: 'Automação de Whatsapp', value: 'whatsappbot' },
  { id: 2, label: 'Whatsapp Marketing', value: 'wppmarketing' },
  { id: 3, label: 'CRM', value: 'crm' }
])

async function editPlan() {
  try {
    const response = await apiClient.put(`/plan/${planID.value}`, {
      name: planName.value,
      features: planFeatures.value,
      value: planValue.value,
      enabled: planEnabled.value
    })

    if (response.data.statusCode === 200) {
      $toast.success('Plano alterado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao alterar o plano!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Ocorreu um erro ao alterar plano !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}
</script>

<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="editplanmodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar Plano</h5>
          <v-btn
            type="button"
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <form @submit.prevent="editPlan">
            <div class="mb-3">
              <label for="plan_name_edit" class="form-label">Nome:</label>
              <input
                type="text"
                class="form-control"
                id="plan_name_edit"
                placeholder="Digite o nome do plano"
                v-model="planName"
              />
            </div>
            <div class="mb-3">
              <div>
                <div class="form-check" v-for="(item, index) in features" :key="item.id">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item.value"
                    :id="'flexCheck' + item.id"
                    v-model="planFeatures"
                  />
                  <label class="form-check-label" :for="'flexCheck' + item.id">
                    {{ item.label }}
                  </label>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="plan_value_edit" class="form-label">Valor da Mensalidade:</label>
              <input
                type="text"
                class="form-control"
                id="plan_value_edit"
                placeholder="Digite o valor da mensalidade"
                v-model="planValue"
                @keyup="maskMoney"
              />
            </div>
            <div class="mb-3">
              <label for="status" class="form-label">Status:</label>
              <select class="form-select" id="plan_status_edit" v-model="planEnabled">
                <option disabled value="undefined">Selecione o status</option>
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </select>
            </div>
            <v-btn type="submit" class="btn btn-primary">Alterar</v-btn>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
