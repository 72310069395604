<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'
import { ref } from 'vue'

const planData = ref({
  name: '',
  features: [],
  value: '',
  enabled: undefined
})
function maskMoney() {
  let v = planData.value.value.replace(/\D/g, '') // Remove tudo que não for dígito

  if (v.length === 0) {
    planData.value.value = ''
    return
  }

  v = (parseInt(v) / 100).toFixed(2) + '' // Divide por 100 e fixa duas casas decimais
  v = v.replace('.', ',') // Substitui ponto por vírgula para a separação de centavos
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') // Coloca pontos a cada 3 dígitos

  planData.value.value = v
}

const features = ref([
  { id: 1, label: 'Automação de Whatsapp', value: 'whatsappbot' },
  { id: 2, label: 'Whatsapp Marketing', value: 'wppmarketing' },
  { id: 3, label: 'CRM', value: 'crm' }
])

async function createPlan() {
  const $toast = useToast()
  try {
    const response = await apiClient.post(`/plan`, planData.value)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Plano criado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao criar o plano!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao criar plano !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)

    return true
  }
}
</script>
<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="createplanmodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <!-- Altera para modal-lg para tornar o modal mais largo -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Cadastro de Plano</h5>
          <v-btn
            icon="mdi:mdi-close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createPlan">
            <div class="mb-3">
              <label for="nome" class="form-label">Nome:</label>
              <input
                type="text"
                class="form-control"
                id="nome"
                placeholder="Digite o nome do plano"
                v-model="planData.name"
              />
            </div>
            <div class="mb-3">
              <div>
                <div class="form-check" v-for="(item, index) in features" :key="item.id">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item.value"
                    :id="'flexCheck' + item.id"
                    v-model="planData.features"
                  />
                  <label class="form-check-label" :for="'flexCheck' + item.id">
                    {{ item.label }}
                  </label>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="mensalidade" class="form-label">Valor da Mensalidade:</label>
              <input
                type="text"
                class="form-control"
                id="mensalidade"
                placeholder="Digite o valor da mensalidade"
                v-model="planData.value"
                @keyup="maskMoney"
              />
            </div>
            <div class="mb-3">
              <label for="status" class="form-label">Status:</label>
              <select class="form-select" id="status" v-model="planData.enabled">
                <option disabled value="undefined">Selecione o status</option>
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </select>
            </div>
            <v-btn type="submit" class="btn btn-primary">Cadastrar</v-btn>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
