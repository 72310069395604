<script setup lang="ts">
import { apiClient } from '../api/ServiceApi'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import CreateCustomer from '../components/modal/customer/CreateCustomer.vue'
import DeleteCustomer from '../components/modal/customer/DeleteCustomer.vue'
import EditCustomer from '../components/modal/customer/EditCustomer.vue'
import WhatsAppConnection from '../components/modal/whatsapp/WhatsAppConnection.vue'
import { RouterLink } from 'vue-router'
import { onBeforeMount, ref } from 'vue'
library.add(fas, faTwitter, faFontAwesome, faWhatsapp)

const allCustomers = ref()
interface Server {
  proxy: boolean
  host: string
  port: number
  key: string
}
const customerData = ref<{
  id: string
  name: string
  email: string
  cpf_cnpj: string
  login: string
  password: string
  phone: string
  plan: string
  server: Server
  enabled: string
} | null>(null)

async function list_customers() {
  try {
    const response = await apiClient.get('/customer')
    if (response['data']['statusCode'] == 200) {
      allCustomers.value = response['data']['allCustomers']
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}

async function populate_model_change(
  customerId: string,
  customerName: string,
  customerEmail: string,
  customerCpf_cnpj: string,
  customerLogin: string,
  customerPassword: string,
  customerPhone: string,
  customerPlan: string,
  customerServer: Server,
  customerEnabled: string
) {
  customerData.value = {
    id: customerId,
    name: customerName,
    email: customerEmail,
    cpf_cnpj: customerCpf_cnpj,
    login: customerLogin,
    password: customerPassword,
    phone: customerPhone,
    plan: customerPlan,
    server: customerServer,
    enabled: customerEnabled
  }
}

onBeforeMount(async () => {
  await list_customers()

  document
    .getElementById('deletecustomermodal')
    ?.addEventListener('show.bs.modal', (event: any) => {
      const relatedTarget = event.relatedTarget as HTMLElement | null
      const customerId = relatedTarget?.dataset.customerId
      const customerName = relatedTarget?.dataset.customerName
      const customerNameField = document.getElementById(
        'customer_to_delete'
      ) as HTMLHeadingElement | null
      const customerIdField = document.getElementById(
        'customer_id_to_delete'
      ) as HTMLHeadingElement | null
      // Verifique se o elemento foi encontrado
      if (
        customerNameField &&
        customerName !== undefined &&
        customerName !== null &&
        customerIdField &&
        customerId !== undefined &&
        customerId !== null
      ) {
        // Modifique o conteúdo do elemento
        customerNameField.textContent = customerName
        customerIdField.textContent = customerId
      }
    })
})
</script>

<template>
  <div class="overflow-auto w-100 table_div">
    <table class="table table-hover">
      <thead class="table-dark">
        <tr>
          <th scope="col" class="user-select-none">Nome</th>
          <th scope="col" class="user-select-none">Email</th>
          <th scope="col" class="user-select-none">CPF/CNPJ</th>
          <th scope="col" class="user-select-none">Telefone</th>
          <th scope="col" class="user-select-none">Plano</th>
          <th scope="col" class="user-select-none">Servidor</th>
          <th scope="col" class="user-select-none">Status</th>
          <th scope="col" class="user-select-none" style="width: 30vh">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in allCustomers" :key="customer.name">
          <td class="align-middle">{{ customer.name }}</td>
          <td class="align-middle">{{ customer.email }}</td>
          <td class="align-middle">{{ customer.cpf_cnpj }}</td>
          <td class="align-middle">{{ customer.phone }}</td>

          <template v-if="customer.plan !== null">
            <td class="align-middle">{{ customer.plan['name'] }}</td>
          </template>
          <template v-else>
            <td class="align-middle bg-danger">Plano Inválido</td>
          </template>

          <td class="align-middle">
            {{ customer.server?.name || 'Servidor Inválido' }}
          </td>

          <td class="align-middle">
            <div class="d-flex justify-content-center">
              <div v-if="customer.enabled">
                <v-btn
                  type="button"
                  class="btn btn-success me-2 user-select-none"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Cliente Ativo"
                >
                  <FontAwesomeIcon :icon="fas.faUser" />
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  type="button"
                  class="btn btn-danger me-2 user-select-none"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Cliente Inativo"
                >
                  <FontAwesomeIcon :icon="fas.faUserLargeSlash" />
                </v-btn>
              </div>
            </div>
          </td>

          <td class="align-middle">
            <div class="d-flex justify-content-center">
              <RouterLink :to="{ name: 'automations', params: { id: customer._id || undefined } }">
                <v-btn
                  type="button"
                  class="btn btn-color me-2"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Automações do cliente"
                >
                  <FontAwesomeIcon :icon="fas.faRobot" />
                  <FontAwesomeIcon :icon="fas.faPencil" />
                </v-btn>
              </RouterLink>

              <v-btn
                type="button"
                class="btn btn-success me-2"
                data-bs-toggle="modal"
                data-bs-target="#whatsappconnectionmodal"
                data-toggle="tooltip"
                data-placement="top"
                title="Conexão com WhatsApp"
                @click="
                  populate_model_change(
                    customer._id,
                    customer.name,
                    customer.email,
                    customer.cpf_cnpj,
                    customer.login,
                    customer.password,
                    customer.phone,
                    customer.plan._id,
                    customer.server,
                    customer.enabled
                  )
                "
              >
                <FontAwesomeIcon :icon="faWhatsapp" />
              </v-btn>

              <v-btn
                type="button"
                class="btn btn-secondary me-2"
                data-bs-toggle="modal"
                data-bs-target="#editcustomermodal"
                @click="
                  populate_model_change(
                    customer._id,
                    customer.name,
                    customer.email,
                    customer.cpf_cnpj,
                    customer.login,
                    customer.password,
                    customer.phone,
                    customer.plan._id,
                    customer.server._id,
                    customer.enabled
                  )
                "
              >
                <FontAwesomeIcon :icon="fas.faUserPen" />
              </v-btn>

              <v-btn
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#deletecustomermodal"
                @click="
                  populate_model_change(
                    customer._id,
                    customer.name,
                    customer.email,
                    customer.cpf_cnpj,
                    customer.login,
                    customer.password,
                    customer.phone,
                    customer.plan._id,
                    customer.server,
                    customer.enabled
                  )
                "
              >
                <FontAwesomeIcon :icon="fas.faTrashAlt" />
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="section p-3">
    <v-btn
      type="button"
      class="btn btn-color"
      data-bs-toggle="modal"
      data-bs-target="#createcustomermodal"
    >
      Cadastrar Cliente
    </v-btn>
    <CreateCustomer />
    <DeleteCustomer :customerData="customerData" />
    <EditCustomer :customerData="customerData" />
    <WhatsAppConnection :customerData="customerData" />
  </div>
</template>

<style scoped>
.table_div {
  max-height: 60vh;
  height: 55vh;
}
.table thead {
  position: sticky;
  top: 0;
  background-color: #b40000; /* Optional: Set a background color for the fixed header */
  z-index: 1; /* Ensure the header is on top of the content */
}

.circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #007bff; /* Cor de fundo do círculo */
  border-radius: 50%; /* Bordas arredondadas para criar um círculo */
  color: #fff; /* Cor do ícone */
}
.btn,
input,
option,
select {
  border-radius: 30px;
}
.btn-color {
  background-color: var(--color-botex);
  color: var(--color-text);
  font-weight: bold;
  border: none;
}
</style>
