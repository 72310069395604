<script setup lang="ts">
import type { Node, Edge } from '@vue-flow/core'
import { Panel, VueFlow } from '@vue-flow/core'
import { Background } from '@vue-flow/background'
import { ref, onBeforeMount, watch } from 'vue'
import { useToast } from 'vue-toast-notification'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import CreateAutoReply from '../components/modal/automation/CreateAutoReply.vue'
import EditAutoReply from '../components/modal/automation/EditAutoReply.vue'
import DeleteAutoReply from '../components/modal/automation/DeleteAutoReply.vue'
import { apiClient } from '../api/ServiceApi'
import { useRoute } from 'vue-router'

const $toast = useToast()
library.add(fas, faTwitter, faFontAwesome)

const props = defineProps<{
  userData: {
    id: string
    name: string
  }
}>()
const rootStyles = getComputedStyle(document.documentElement)
const backgroundColorMenu = rootStyles.getPropertyValue('--color-botex')
const backgroundColorSubMEnu = rootStyles.getPropertyValue('--color-botex-secondary')
const backgroundColorItem = rootStyles.getPropertyValue('--color-green')
const textColor = rootStyles.getPropertyValue('--color-text')
const route = useRoute()
const currentTab = ref('edit')

const customerId = ref()
const customerName = ref('')
const allAutomations = ref()
const existsMainMenu = ref(false)
const automationData = ref<{
  id: string
  input?: string[]
  response: string
  mediaType?: string
  media?: string
  automationPeriod?: string[]
  automationOrder?: string
  automationDelay?: string
  automationCustomer?: string
  automationEnabled?: boolean
  automationMenus?: { _id: string; menuname: string }[]
  automationIsmenu?: boolean
  automationMenuname?: string
  automationMenulink?: string
  waitforhumaniteration?: boolean
} | null>(null)

//Settings
const customerSettings = ref({
  invalidAttempsAction: false,
  invalidAttemps: 1,
  invalidAttempsMessage: ''
})

if (typeof route.params.id === 'string') {
  customerId.value = route.params.id
} else {
  customerId.value = route.params.id[0] || ''
}

async function getCustomerSettings() {
  try {
    const { data, status } = await apiClient.get(`/customer/${customerId.value}`)

    if (status === 200 && data.statusCode === 200) {
      if (data.Customer.settings.length > 0) {
        customerSettings.value = data.Customer.settings[0]
      }
    } else {
      console.error('Falha ao obter configurações: statusCode', data.statusCode)
    }
  } catch (error) {
    console.error('Erro ao buscar configurações do cliente:', error)
  }
}

async function listMenus(customerID: string) {
  try {
    const response = await apiClient.get(`/automation/bycustomer/${customerID}`)
    if (response['data']['statusCode'] == 200) {
      return response['data']['allAutomations'].filter(
        (item: { ismenu: boolean }) => item.ismenu === true
      )
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}

function translateDaysOfWeek(period: string[]) {
  const daysOfWeek: { [key: string]: string } = {
    0: 'Dom',
    1: 'Seg',
    2: 'Ter',
    3: 'Qua',
    4: 'Qui',
    5: 'Sex',
    6: 'Sáb'
  }

  return period.map((day) => daysOfWeek[day])
}
function translateMediaType(mediatype: string): string {
  const mediaTypes: { [key: string]: string } = {
    text: 'Texto',
    img: 'Imagem',
    movie: 'Video',
    sound: 'Audio'
  }

  return mediaTypes[mediatype] || mediatype
}
async function populateModelDelete(id: string, response: string) {
  automationData.value = {
    id: id,
    response: response
  }
}
async function populate_model_edit(
  id: string,
  response: string,
  input_message: string[],
  media_type: string,
  media_name: string,
  automation_period: string[],
  automation_order: string,
  automation_delay: string,
  automation_customer: string,
  automationIsmenu: boolean,
  automation_enabled: boolean,
  automationMenuname?: string,
  automationMenulink?: string,
  automationWaitforhumaniteration?: boolean
) {
  const automationmenus = await listMenus(automation_customer)
  automationData.value = {
    id: id,
    input: input_message,
    response: response,
    mediaType: media_type,
    media: media_name,
    automationPeriod: automation_period,
    automationOrder: automation_order,
    automationDelay: automation_delay,
    automationCustomer: automation_customer,
    automationMenus: automationmenus,
    automationIsmenu: automationIsmenu,
    automationEnabled: automation_enabled,
    automationMenuname: automationMenuname,
    automationMenulink: automationMenulink,
    waitforhumaniteration: automationWaitforhumaniteration
  }
}

async function updateSettings() {
  try {
    const response = await apiClient.put(`/customer/${customerId.value}`, {
      settings: customerSettings.value
    })
    if (response['data']['statusCode'] == 200) {
      $toast.success('Configurações salvas com sucesso !', {
        duration: 5000,
        position: 'top-right'
      })
      // Recarrega as configurações atualizadas
      await getCustomerSettings()
    } else {
      $toast.error('Fala ao alterar configurações!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Ocorreu um erro ao alterar as configurações !', {
      duration: 5000,
      position: 'top-right'
    })
    setTimeout(() => {
      location.reload()
    }, 1000)

    return true
  }
}

async function getCustomerAutomations() {
  let response
  try {
    response = await apiClient.get(`/automation/bycustomer/${customerId.value}`)
    if (
      response['data']['allAutomations'].length === 0 ||
      response['data']['allAutomations'] === undefined
    ) {
      response = await apiClient.get(`/customer/${customerId.value}`)
      customerName.value = response['data']['Customer']['name']
      console.log('Cliente ainda não tem automações')
      // if (autoreplywizard.value) {
      //   autoreplywizard.value.click()
      // } else {
      //   console.error('Hidden button not found')
      // }
      // As linhas comentadas acima realizam a abertura do wizard
    } else {
      allAutomations.value = response['data']['allAutomations']
      customerName.value = response['data']['allAutomations'][0]['customer']['name']
    }
    if (allAutomations && allAutomations.value) {
      existsMainMenu.value = allAutomations.value.some(
        (item: { ismenu: boolean; menulink?: string }) => item.ismenu === true && !item.menulink
      )
    }

    if (response['data']['statusCode'] == 200) {
      $toast.success('Sucesso ao listar !', { duration: 1000, position: 'top-right' })
    } else {
      $toast.error('Falha ao obter automações do cliente !', {
        duration: 5000,
        position: 'top-right'
      })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Ocorreu um erro ao obter o cliente !', {
      duration: 5000,
      position: 'top-right'
    })
  }
}

onBeforeMount(async () => {
  await getCustomerAutomations()
})

let nodes: Node[]
let edges: Edge[]

function definePosition(
  automation: any,
  index: number,
  nodesPositions: { [key: string]: { x: number; y: number } }
) {
  let positionY = 100 // Posição inicial em Y

  // Verifica se há um nó anterior com base no menulink
  const previousNodeId = automation.menulink
  if (previousNodeId && nodesPositions[previousNodeId]) {
    positionY = nodesPositions[previousNodeId].y + 400 // Ajuste de 150 na posição Y
  }

  // Encontra a posição X baseada no último nó na mesma linha
  let positionX = 500 // Posição inicial em X para a linha atual
  for (const nodeId in nodesPositions) {
    if (nodesPositions[nodeId].y === positionY) {
      positionX = Math.max(positionX, nodesPositions[nodeId].x + 350) // Incremento de 250 na posição X
    }
  }

  // Salva a posição do nó atual
  nodesPositions[automation._id] = { x: positionX, y: positionY }

  return { x: positionX, y: positionY }
}

function getContentByMediaType(automation: any, index: any) {
  if (automation.mediatype === 'text') {
    return automation.response || `Item ${index + 1}`
  } else if (automation.mediatype === 'audio') {
    return 'Audio'
  } else if (automation.mediatype === 'pdf') {
    return 'Documento PDF'
  } else if (automation.mediatype === 'img') {
    return 'Imagem'
  } else {
    return `Item ${index + 1}` // Se não for definido, retorna um valor padrão
  }
}

function createNodesAndEdges(automations: any[]) {
  const nodesNew: Node[] = []
  const edgesNew: Edge[] = []
  const nodesPositions: { [key: string]: { x: number; y: number } } = {}
  let node: Node
  automations.forEach((automation, index) => {
    const position = definePosition(automation, index, nodesPositions)

    if (automation.ismenu && !automation.menulink) {
      node = {
        id: automation._id,
        label: getContentByMediaType(automation, index),
        position: position,
        style: {
          backgroundColor: backgroundColorMenu.trim(),
          borderRadius: '15px',
          color: textColor.trim()
        }
      }
    } else if (automation.ismenu && automation.menulink) {
      node = {
        id: automation._id,
        label: getContentByMediaType(automation, index),
        position: position,
        style: {
          backgroundColor: backgroundColorSubMEnu.trim(),
          borderRadius: '15px',
          color: textColor.trim()
        }
      }
    } else {
      node = {
        id: automation._id,
        label: getContentByMediaType(automation, index),
        position: position,
        style: {
          backgroundColor: backgroundColorItem.trim(),
          borderRadius: '15px',
          color: textColor.trim()
        }
      }
    }

    nodesNew.push(node)

    if (automation.menulink) {
      const edge: Edge = {
        id: `e${index}-${index + 1}`,
        source: automation.menulink,
        target: automation._id,
        animated: true,
        style: { backgroundColor: backgroundColorMenu.trim() }
      }
      edgesNew.push(edge)
    }
  })

  nodes = nodesNew
  edges = edgesNew
}

watch(allAutomations, (newVal) => {
  createNodesAndEdges(newVal)
})
</script>

<template>
  <v-card flat>
    <v-tabs v-model="currentTab" bg-color="grey-lighten-3">
      <v-tab value="edit"><v-icon icon="mdi-puzzle-edit"></v-icon>Edição</v-tab>
      <v-tab value="view"><v-icon icon="mdi-vector-radius"></v-icon>Visualização de Fluxo</v-tab>
      <v-tab value="settings" @click="getCustomerSettings"
        ><v-icon icon="mdi-tune-vertical-variant"></v-icon>Configurações</v-tab
      >
    </v-tabs>
    <v-tabs-window v-model="currentTab">
      <v-tabs-window-item value="edit">
        <p class="text-center">
          A palavra&nbsp;<b>inicio</b>&nbsp;é reservada para reiniciar o atendimento e enviar o menu
          principal, o número&nbsp;<b>0</b>&nbsp;e a palavra&nbsp;<b>voltar</b>&nbsp;são reservados
          para voltar ao menu anterior
        </p>
        <div class="overflow-auto w-100 table_div">
          <table class="table table-hover">
            <thead class="table-dark">
              <tr>
                <th scope="col" class="user-select-none">Entrada do Usuário</th>
                <th scope="col" class="user-select-none">Resposta do bot</th>
                <th scope="col" class="user-select-none">Tipo de resposta</th>
                <th scope="col" class="user-select-none">Periodo</th>
                <th scope="col" class="user-select-none">Ordem de envio</th>
                <th scope="col" class="user-select-none">Status</th>
                <th scope="col" class="user-select-none" style="width: 20vh">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="automation in allAutomations" v-bind:key="automation.name">
                <td>{{ automation.inputs.join(', ') }}</td>
                <td>{{ automation.response }}</td>
                <td>{{ translateMediaType(automation.mediatype) }}</td>
                <td>{{ translateDaysOfWeek(automation.period).join(', ') }}</td>
                <td>{{ automation.order }}</td>
                <td>
                  <div v-if="automation.enabled">
                    <v-btn
                      type="button"
                      class="btn btn-success user-select-none"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Resposta Ativa"
                    >
                      <FontAwesomeIcon :icon="fas.faCheck" />
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      type="button"
                      class="btn btn-danger user-select-none"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Resposta Inativa"
                    >
                      <FontAwesomeIcon :icon="fas.faXmark" />
                    </v-btn>
                  </div>
                </td>
                <td>
                  <v-btn
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#editautomationmodal"
                    @click="
                      populate_model_edit(
                        automation._id,
                        automation.response,
                        automation.inputs,
                        automation.mediatype,
                        automation.media,
                        automation.period,
                        automation.order,
                        automation.delay,
                        automation.customer._id,
                        automation.ismenu,
                        automation.enabled,
                        automation.menuname,
                        automation.menulink,
                        automation.waitforhumaniteration
                      )
                    "
                  >
                    <FontAwesomeIcon :icon="fas.faEdit" />
                  </v-btn>
                  <v-btn
                    type="button"
                    class="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteautomationmodal"
                    @click="populateModelDelete(automation._id, automation.response)"
                  >
                    <FontAwesomeIcon :icon="fas.faTrashAlt" />
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="section p-3">
          <v-btn
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#createautomationmodal"
          >
            Cadastrar Resposta Automatizada
          </v-btn>
        </div>
      </v-tabs-window-item>
      <v-tabs-window-item value="view">
        <div style="width: 100%; height: 75vh">
          <VueFlow :nodes="nodes" :edges="edges" :default-viewport="{ zoom: 0.5 }">
            <Background />
            <Panel class="subtitle-panel" position="top-left">
              <v-card class="mx-auto d-none d-md-flex" max-width="400">
                <v-list density="compact">
                  <v-list-subheader>Legenda</v-list-subheader>
                  <v-list-item>
                    <template v-slot:prepend>
                      <v-icon color="var(--color-botex)" icon="mdi-brightness-1"></v-icon>
                      <v-list-item-title v-text="'Menu Principal'"></v-list-item-title>
                    </template>
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:prepend>
                      <v-icon color="var(--color-botex-secondary)" icon="mdi-brightness-1"></v-icon>
                      <v-list-item-title v-text="'SubMenu'"></v-list-item-title>
                    </template>
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:prepend>
                      <v-icon color="var(--color-green)" icon="mdi-brightness-1"></v-icon>
                      <v-list-item-title v-text="'Item de Menu'"></v-list-item-title>
                    </template>
                  </v-list-item>
                </v-list>
              </v-card>
            </Panel>
          </VueFlow>
        </div>
      </v-tabs-window-item>
      <v-tabs-window-item value="settings">
        <v-card
          width="500"
          class="p-4 ma-5"
          title="Liberação de atendimento"
          subtitle="Liberar após tentativas inválidas"
        >
          <v-sheet>
            <v-form ref="form">
              <v-row flat>
                <v-switch
                  flat
                  color="primary"
                  v-model="customerSettings.invalidAttempsAction"
                  label="Ativar"
                ></v-switch>
              </v-row>
              <v-row>
                <v-slider
                  min="1"
                  max="10"
                  step="1"
                  v-model="customerSettings.invalidAttemps"
                  color="#5170ff"
                  label="Tentativas até liberar"
                ></v-slider
                >{{ customerSettings.invalidAttemps }}
              </v-row>
              <v-row>
                <p>Mensagem que será enviada para o cliente</p>
              </v-row>
              <v-row>
                <v-textarea v-model="customerSettings.invalidAttempsMessage"> </v-textarea>
              </v-row>
              <v-row class="justify-center">
                <v-btn @click="updateSettings">Salvar configuração</v-btn>
              </v-row>
            </v-form>
          </v-sheet>
        </v-card>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-card>
  <CreateAutoReply :customerId :existsMainMenu />
  <EditAutoReply :automationData="automationData" :existsMainMenu />
  <DeleteAutoReply :automationData="automationData" />
</template>

<style scoped>
.table_div {
  max-height: 60vh;
}
.table thead {
  position: sticky;
  top: 0;
  background-color: #b40000; /* Optional: Set a background color for the fixed header */
  z-index: 1; /* Ensure the header is on top of the content */
}

.circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #007bff; /* Cor de fundo do círculo */
  border-radius: 50%; /* Bordas arredondadas para criar um círculo */
  color: #fff; /* Cor do ícone */
}
.automation-test {
  text-align: center;
}
.btn {
  border-radius: 49px;
}
.btn-primary {
  background-color: var(--color-botex);
  color: var(--color-text);
  font-weight: bold;
  border: none;
}
.subtitle-panel {
  background-color: var(--color-legend);
  border-radius: 10px;
  color: var(--color-text);
  padding: 10px;
  user-select: none;
}
.subtitle-panel h3 {
  text-align: center;
}
.subtitle-panel ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 3px;
}

.icon.main {
  background: var(--color-botex);
}

.icon.sub {
  background: var(--color-botex-secondary);
}
.icon.item {
  background: var(--color-green);
}
</style>
