<template>
  <v-navigation-drawer>
    <v-list-item title="Conversas"></v-list-item>
    <v-divider></v-divider>
    <v-list-item v-for="(item, index) in chats" :key="index">
      <v-list-item
        slim
        prepend-icon="mdi-account-circle-outline"
        link
        :title="item.name"
      ></v-list-item>
    </v-list-item>
  </v-navigation-drawer>

  <v-container>
    <div class="d-flex bg-surface-variant pa-2 rounded w-33 right-100">.w-33</div>
    <div class="d-flex bg-surface-variant pa-2 rounded w-33 left-100">.w-33</div>
  </v-container>
  <v-container>
    <v-sheet>
      <v-list>
        <v-list-item
          v-for="message in messages"
          :key="message.id"
          :class="{ 'text-right': isSentByMe(message), 'text-left': !isSentByMe(message) }"
          :title="message.member"
          :subtitle="message.data"
          :prepend-avatar="!isSentByMe(message) ? getAvatarUrl(message.member) : undefined"
          :append-avatar="isSentByMe(message) ? getAvatarUrl(message.member) : undefined"
        >
          <template v-slot:subtitle>
            <div class="truncate-subtitle">{{ message.data }}</div>
          </template>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { apiClient, whatsappURLManager } from '../api/ServiceApi'

const props = defineProps<{
  userData: {
    id: string
    name: string
  } | null
}>()

const userID = ref('')
const userName = ref('')
interface Server {
  proxy: boolean
  host: string
  port: number
  key: string
}

const customerData = ref<{
  id: string
  name: string
  email: string
  cpf_cnpj: string
  login: string
  password: string
  phone: string
  plan: string
  server: Server
  enabled: string
} | null>(null)

const chats = ref<any[]>([])

watch(
  () => props.userData,
  async (newValue) => {
    if (newValue) {
      userID.value = newValue.id
      userName.value = newValue.name
      await getUserData()
      await getConectionChats()
    }
  },
  { immediate: true }
)

const messages = [
  { id: 1, member: 'Alice', data: 'Hello, how are you? I hope everything is going well with you.' },
  {
    id: 2,
    member: 'Rhennan',
    data: "I'm good, thanks! And you? I've been busy with work but can’t complain."
  },
  {
    id: 3,
    member: 'Alice',
    data: "Doing great, working on a new project. It's a challenging but exciting venture."
  },
  {
    id: 4,
    member: 'Rhennan',
    data: "That's awesome! Tell me more about it. What kind of project is it?"
  },
  {
    id: 5,
    member: 'Alice',
    data: "Sure, it's a web app built with Vue.js and some cutting-edge technologies. It's designed to streamline workflows."
  },
  {
    id: 6,
    member: 'Rhennan',
    data: 'Sounds interesting! Need any help? I have some experience with Vue.js and might be able to contribute.'
  },
  {
    id: 7,
    member: 'Alice',
    data: 'I might take you up on that! We are focusing on user experience right now, and your insights could be valuable.'
  },
  {
    id: 8,
    member: 'Rhennan',
    data: "Absolutely! Just let me know what you need. I'm looking forward to collaborating."
  },
  {
    id: 9,
    member: 'Alice',
    data: 'Great! I’ll send you some details soon. By the way, have you tried the new coffee place downtown?'
  },
  {
    id: 10,
    member: 'Rhennan',
    data: "Not yet, but I've heard good things about it. I might check it out this weekend."
  },
  {
    id: 11,
    member: 'Alice',
    data: 'You should! The ambiance is really nice and they have a great selection of pastries.'
  },
  {
    id: 12,
    member: 'Rhennan',
    data: "Sounds tempting. I'll definitely give it a try. Do you have any other recommendations?"
  },
  {
    id: 13,
    member: 'Alice',
    data: "If you like books, there's a quaint bookstore nearby that also serves amazing coffee."
  },
  {
    id: 14,
    member: 'Rhennan',
    data: "I love bookstores! I'll make sure to visit both places. Thanks for the tips!"
  },
  {
    id: 15,
    member: 'Alice',
    data: "You're welcome! Let me know if you need any more recommendations. How’s your project going?"
  },
  {
    id: 16,
    member: 'Rhennan',
    data: 'It’s progressing well. We’re in the testing phase now. It’s always great to see everything come together.'
  },
  {
    id: 17,
    member: 'Alice',
    data: 'That’s awesome. Testing can be intense, but it’s rewarding when you see the results.'
  },
  {
    id: 18,
    member: 'Rhennan',
    data: 'Definitely! I’m also learning a lot through this process, which is a huge bonus.'
  },
  {
    id: 19,
    member: 'Alice',
    data: 'That’s great to hear. Continuous learning is key in this field. Have you encountered any major challenges?'
  },
  {
    id: 20,
    member: 'Rhennan',
    data: 'A few, but nothing too overwhelming. Just the usual bugs and performance issues that we’re working through.'
  },
  {
    id: 21,
    member: 'Alice',
    data: 'Sounds familiar! It’s all part of the process. What’s the most interesting thing you’ve learned so far?'
  },
  {
    id: 22,
    member: 'Rhennan',
    data: 'I’ve been diving into some advanced state management techniques. It’s fascinating to see how much more you can control.'
  },
  {
    id: 23,
    member: 'Alice',
    data: 'That’s really cool. Advanced state management can make a big difference in app performance and scalability.'
  },
  {
    id: 24,
    member: 'Rhennan',
    data: 'Exactly. It’s all about finding the right balance. I’m looking forward to applying these techniques in future projects.'
  },
  {
    id: 25,
    member: 'Alice',
    data: 'I’m sure you’ll do great. It’s always exciting to implement new skills in real-world applications.'
  },
  {
    id: 26,
    member: 'Rhennan',
    data: 'Thanks! I appreciate your support. By the way, have you seen any good movies lately?'
  },
  {
    id: 27,
    member: 'Alice',
    data: 'Actually, yes. I watched a fantastic documentary on tech innovations last weekend.'
  },
  { id: 28, member: 'Rhennan', data: 'That sounds intriguing. What was it about?' },
  {
    id: 29,
    member: 'Alice',
    data: 'It covered a range of topics, from AI advancements to sustainable tech. Very inspiring!'
  },
  {
    id: 30,
    member: 'Rhennan',
    data: 'I’ll have to check that out. I’m always interested in tech documentaries.'
  },
  {
    id: 31,
    member: 'Alice',
    data: 'I highly recommend it. If you’re into that, there’s also a great podcast series on similar topics.'
  },
  {
    id: 32,
    member: 'Rhennan',
    data: 'Perfect! I’ll look for both. Thanks for the recommendation.'
  },
  {
    id: 33,
    member: 'Alice',
    data: 'You’re welcome! Let me know what you think once you’ve had a chance to watch or listen.'
  },
  {
    id: 34,
    member: 'Rhennan',
    data: 'I will. Switching topics, have you had any interesting tech meetups or events recently?'
  },
  {
    id: 35,
    member: 'Alice',
    data: 'Yes, actually. I attended a webinar on the future of web development. It was quite enlightening.'
  },
  {
    id: 36,
    member: 'Rhennan',
    data: 'That sounds like a great event. What were the key takeaways?'
  },
  {
    id: 37,
    member: 'Alice',
    data: 'The focus was on emerging frameworks and the shift towards more modular architecture. A lot of exciting stuff on the horizon.'
  },
  {
    id: 38,
    member: 'Rhennan',
    data: 'Interesting! Modular architecture is definitely the way forward. I’m excited to see how it evolves.'
  },
  {
    id: 39,
    member: 'Alice',
    data: 'Absolutely. It’s going to make development more flexible and scalable.'
  },
  {
    id: 40,
    member: 'Rhennan',
    data: 'Agreed. It’s an exciting time to be in the tech industry. Do you have any upcoming projects you’re excited about?'
  },
  {
    id: 41,
    member: 'Alice',
    data: 'Yes, I’m working on a new feature for the web app that incorporates real-time data visualization.'
  },
  {
    id: 42,
    member: 'Rhennan',
    data: 'That sounds like a valuable feature. Real-time data is becoming more critical for many applications.'
  },
  {
    id: 43,
    member: 'Alice',
    data: 'Definitely. It’s a challenging feature to implement, but it’s worth the effort.'
  },
  {
    id: 44,
    member: 'Rhennan',
    data: 'I’m sure it will turn out great. If you need any help or feedback, feel free to reach out.'
  },
  {
    id: 45,
    member: 'Alice',
    data: 'Thank you! I might take you up on that. It’s always good to have another perspective.'
  },
  {
    id: 46,
    member: 'Rhennan',
    data: 'Anytime. I’m always here to help. On a different note, are you planning any vacations soon?'
  },
  {
    id: 47,
    member: 'Alice',
    data: 'Actually, yes. I’m planning a trip to the mountains next month. Looking forward to some relaxation.'
  },
  {
    id: 48,
    member: 'Rhennan',
    data: 'That sounds wonderful. The mountains are a great place to unwind. I hope you have a fantastic time.'
  },
  {
    id: 49,
    member: 'Alice',
    data: 'Thanks! I’m really excited. I’ve been needing a break, and the mountains seem perfect.'
  },
  {
    id: 50,
    member: 'Rhennan',
    data: 'I’m sure you’ll enjoy it. Let’s catch up again before you leave. It’s always good to stay in touch.'
  }
]

function isSentByMe(message: { member: string }) {
  return message.member === 'Rhennan'
}

function getAvatarUrl(member: string) {
  return member === userName.value
    ? 'https://randomuser.me/api/portraits/women/8.jpg'
    : 'https://randomuser.me/api/portraits/men/8.jpg'
}

async function getUserData() {
  try {
    const response = await apiClient.get(`/customer/${userID.value}`)
    if (response.data.statusCode === 200) {
      const data = response.data.Customer
      customerData.value = {
        id: data._id,
        name: data.name,
        email: data.email,
        cpf_cnpj: data.cpf_cnpj,
        login: data.login,
        password: data.password,
        phone: data.phone,
        plan: data.plan,
        server: data.server,
        enabled: data.enabled
      }
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
  }
}

async function getConectionChats() {
  if (customerData.value) {
    const server = customerData.value.server
    try {
      const response = await whatsappURLManager(
        server.proxy,
        server.host,
        server.port,
        server.key
      ).get(`/client/getChats/${userID.value}`)
      chats.value = response.data.chats
    } catch (e) {
      console.log('Erro ao obter status do bot', e)
    }
  } else {
    console.log('Customer data não está disponível')
  }
}
</script>

<style scoped>
.truncate-subtitle {
  background-color: red;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust as needed */
}
</style>
