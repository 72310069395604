<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue'
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
library.add(fas, faTwitter, faFontAwesome)
const props = defineProps<{
  customerId: string
  existsMainMenu: boolean
}>()
const allMenus = ref()
const inputs = ref(['']) // Inicializa com um input vazio
const mediaType = ref('text')
const selectedFile = ref<File | null>(null)
const filePreview = ref<string | null>(null)
const selectedDays = ref<number[]>([])
const replyType = ref()
const mediaFormData = new FormData()
const daysOfWeek = [
  { abbr: 0, full: 'Dom' },
  { abbr: 1, full: 'Seg' },
  { abbr: 2, full: 'Ter' },
  { abbr: 3, full: 'Qua' },
  { abbr: 4, full: 'Qui' },
  { abbr: 5, full: 'Sex' },
  { abbr: 6, full: 'Sáb' }
]

const addInput = () => {
  inputs.value.push('')
}

const removeInput = (index: number) => {
  inputs.value.splice(index, 1)
}

const selectAllDays = () => {
  selectedDays.value = daysOfWeek.map((day) => day.abbr)
}

const handleFileChange = async (event: Event) => {
  const input = event.target as HTMLInputElement
  if (input.files && input.files[0]) {
    selectedFile.value = input.files[0]

    const reader = new FileReader()
    reader.onload = (e) => {
      filePreview.value = e.target?.result as string
    }
    reader.readAsDataURL(selectedFile.value)

    // Limpa o FormData e adiciona novamente o arquivo
    mediaFormData.delete('file')
    mediaFormData.delete('userId')
    mediaFormData.append('file', selectedFile.value)
    mediaFormData.append('userId', props.customerId) // Adicione outros campos conforme necessário
  }
}
const removeSelectedFile = () => {
  selectedFile.value = null
  filePreview.value = null
}

const automationData = reactive({
  inputs: [''],
  response: '',
  period: [] as string[],
  mediatype: '',
  media: '',
  customer: props.customerId,
  order: '0',
  delay: '0',
  ismenu: false,
  menuname: undefined as string | undefined,
  menulink: undefined as string | undefined,
  waitforhumaniteration: false,
  enabled: true
})

async function listMenus() {
  try {
    const response = await apiClient.get(`/automation/bycustomer/${props.customerId}`)
    if (response['data']['statusCode'] == 200) {
      allMenus.value = response['data']['allAutomations'].filter(
        (item: { ismenu: boolean }) => item.ismenu === true
      )
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}
onMounted(async () => {
  await listMenus()
})

const createAutomation = async () => {
  automationData.mediatype = mediaType.value
  automationData.period = selectedDays.value.map(String).sort()

  const $toast = useToast()

  // Remover `menulink` se estiver em branco
  if (replyType.value === 'menu' || replyType.value === 'sub') {
    automationData.ismenu = true
  } else {
    automationData.ismenu = false
  }
  if (!automationData.menulink) {
    delete automationData.menulink
  }
  if (!automationData.menuname) {
    delete automationData.menuname
  }
  try {
    if (mediaType.value !== 'text') {
      const sendImage = await apiClient.post('/upload/media', mediaFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      automationData.media = sendImage.data.filename
      automationData.response = '-'
    }

    const response = await apiClient.post('/automation', automationData)
    if (response.data.statusCode === 200) {
      $toast.success('Automação criada com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao criar automação !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    console.log('Debug error:', e)
    $toast.error('Erro interno ao criar a automação !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}
</script>

<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="createautomationmodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <!-- Altera para modal-lg para tornar o modal mais largo -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="autoreplymodal">Cadastro de Resposta automática</h5>
          <v-btn
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createAutomation">
            <div class="row">
              <div v-for="(input, index) in inputs" :key="index" class="col-md-4">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      v-model="automationData.inputs[index]"
                      type="text"
                      class="form-control"
                      placeholder="Mensagem"
                    />
                    <v-btn
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="removeInput(index)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Clique no X para remover uma entrada."
                    >
                      X
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mb-3"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Aqui serão adicionadas as mensagens que o cliente deve enviar para obter esta resposta. No caso de um menu, pode ser algo simples como o número '1' para a opção X. Se esta resposta for o menu principal, é possível deixá-la sem uma mensagem de entrada. Dessa forma, qualquer mensagem enviada pelo cliente acionará o bot para exibir este menu. No entanto, somente o menu principal pode ficar sem uma mensagem de entrada; todas as outras opções precisam ter uma mensagem específica de entrada."
            >
              <span class="hover-cursor badge rounded-pill btn-color" @click="addInput">
                Adicionar Entrada
              </span>
            </div>
            <div class="mb-3">
              <label for="mediatype">Tipo de mídia da resposta:</label>
              <select class="form-control form-control-sm" id="mediatype" v-model="mediaType">
                <option value="text">Texto</option>
                <option value="pdf">PDF</option>
                <option value="img">Imagem</option>
                <option value="audio">Áudio</option>
                <!-- <option value="video">Vídeo</option> -->
              </select>
            </div>
            <div class="mb-3">
              <div v-if="mediaType === 'text'">
                <label for="funcionalidades" class="form-label">Resposta:</label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Aqui está a resposta que o bot enviará para o cliente."
                  v-model="automationData.response"
                ></textarea>
              </div>
              <div v-if="['pdf', 'img', 'audio', 'video'].includes(mediaType)">
                <label for="funcionalidades" class="form-label">Selecione a mídia</label>
                <input
                  v-if="mediaType === 'img'"
                  type="file"
                  class="form-control"
                  @change="handleFileChange"
                />
                <input
                  v-if="mediaType === 'audio'"
                  type="file"
                  class="form-control"
                  accept="audio/*"
                  @change="handleFileChange"
                />
                <input
                  v-if="mediaType === 'pdf'"
                  type="file"
                  class="form-control"
                  accept="application/pdf"
                  @change="handleFileChange"
                />
                <div v-if="mediaType === 'img' && filePreview" class="image-preview-container">
                  <img :src="filePreview" alt="Image Preview" class="image-preview" />
                  <v-btn type="button" class="remove-image-btn" @click="removeSelectedFile">
                    X
                  </v-btn>
                </div>
                <div v-if="mediaType === 'audio' && filePreview" class="audio-preview-container">
                  <audio controls>
                    <source :src="filePreview" type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  <v-btn type="button" class="remove-audio-btn" @click="removeSelectedFile">
                    X
                  </v-btn>
                </div>
              </div>
            </div>

            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Nesta seção, você poderá configurar uma determinada resposta para ser enviada apenas em um dia específico. Essa opção é interessante para promoções ou produtos que podem ser ofertados em dias específicos. Caso contrário, o ideal é marcar todos os dias."
            >
              <div class="row">
                <label class="col-md-12 control-label" for="checkboxes">Dias da Semana</label>
              </div>
              <div class="row">
                <div class="form-group">
                  <label
                    v-for="(day, index) in daysOfWeek"
                    :key="index"
                    class="col-md-1 checkbox-inline"
                  >
                    <input
                      type="checkbox"
                      name="checkboxes"
                      :id="'checkboxes-' + index"
                      class="checkbox"
                      :value="day.abbr"
                      v-model="selectedDays"
                    />
                    {{ day.full }}
                  </label>
                  <v-btn type="button" class="btn btn-color" @click="selectAllDays">
                    Todos os Dias
                  </v-btn>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <div
                  class="mb-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Caso o bot precise enviar mais de uma resposta por opção, você pode cadastrar várias respostas com a mesma entrada e definir a ordem de envio delas. Por exemplo, a mensagem com ordem 0 será a primeira, a mensagem com ordem 1 será a segunda, e assim por diante, sempre da menor para a maior ordem. Se todas tiverem a mesma ordem, as mais antigas serão enviadas primeiro e as mais novas por último."
                >
                  <label for="order" class="form-label">Ordem:</label>
                  <input
                    type="number"
                    id="order"
                    class="form-control"
                    v-model="automationData.order"
                    min="0"
                  />
                </div>
                <div
                  class="mb-2 hover-cursor badge rounded-pill bg-secondary text-dark bg-opacity-75"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Com essa opção marcada, após o bot enviar esta mensagem, ele irá parar de responder às mensagens do cliente por um tempo determinado. A partir desse ponto, o atendente assumirá o atendimento."
                >
                  <input
                    type="checkbox"
                    id="releaseforservice"
                    v-model="automationData.waitforhumaniteration"
                  />
                  <label for="releaseforservice" class="checkbox-inline">Liberar Atendimento</label>
                </div>
              </div>
              <div class="col-md-2">
                <div
                  class="mb-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="É possível definir um atraso para essa mensagem. Assim, quando o cliente digitar uma opção e for essa com o atraso, aparecerá 'digitando...' ou 'gravando áudio...', evitando o envio de muitas mensagens de uma vez só e tornando o atendimento mais humano."
                >
                  <label for="order" class="form-label">Atraso(Seg)</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="automationData.delay"
                    min="0"
                    max="10000"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="mb-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Nesta sessão, você precisa definir o tipo de resposta. Pode ser um menu, onde obrigatoriamente um fluxo de atendimento deve ter um menu principal único. Um menu pode ter vários submenus e várias respostas associadas, tanto para o menu quanto para os submenus."
                >
                  <label class="form-check-label" for="itemDeMenu">Tipo de resposta</label>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="menuPrincipal"
                      value="menu"
                      v-model="replyType"
                      :disabled="existsMainMenu"
                    />
                    <label class="form-check-label" for="menuPrincipal">Menu Principal</label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="subMenu"
                      value="sub"
                      v-model="replyType"
                    />
                    <label class="form-check-label" for="subMenu">SubMenu</label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="itemDeMenu"
                      value="item"
                      v-model="replyType"
                    />
                    <label class="form-check-label" for="itemDeMenu">Item de Menu</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-3">
                  <label v-if="replyType === 'menu'" class="form-check-label" for="itemDeMenu"
                    >Nome para o menu</label
                  >
                  <label v-if="replyType === 'sub'" class="form-check-label" for="itemDeMenu"
                    >Nome para o submenu</label
                  >
                  <input
                    v-if="replyType === 'sub' || replyType === 'menu'"
                    type="text"
                    class="form-control"
                    v-model="automationData.menuname"
                    placeholder="Nome"
                    required
                  />
                  <label
                    v-if="replyType === 'sub' || replyType === 'item'"
                    class="form-check-label"
                    for="itemDeMenu"
                    >Menu associado</label
                  >
                  <select
                    v-if="replyType === 'sub' || replyType === 'item'"
                    class="form-select"
                    v-model="automationData.menulink"
                    required
                  >
                    <option disabled value="">Menu associado</option>
                    <option v-for="menu in allMenus" :key="menu._id" :value="menu._id">
                      {{ menu.menuname }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
            <div
              class="mb-3"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Aqui você pode inativar uma resposta para que ela seja ignorada e não enviada para o cliente. É importante não fazer isso para um menu, pois isso pode impactar na experiência de atendimento do cliente."
            >
              <label for="status" class="form-label">Status:</label>
              <select class="form-select" id="status" v-model="automationData.enabled">
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </select>
            </div>
            <v-btn type="submit" class="btn btn-color">Cadastrar</v-btn>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-group {
  margin-bottom: 10px;
}
.input-group input {
  border-radius: 10px;
}

.hover-cursor:hover {
  background-color: rgb(40, 5, 87);
  cursor: pointer;
}

.image-preview-container,
.audio-preview-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Altura fixa para a imagem ou áudio */
  margin-top: 10px;
}

.image-preview {
  max-width: 100%; /* Garante que a imagem não ultrapasse o contêiner */
  max-height: 100%; /* Garante que a imagem não ultrapasse o contêiner */
}

.remove-image-btn,
.remove-audio-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1; /* Garante que o botão de remoção fique sobre a imagem ou áudio */
}

/* CSS personalizado para garantir que a setinha apareça */
.form-control.form-control-sm {
  appearance: auto; /* Garante a aparência padrão do navegador */
  -moz-appearance: auto; /* Para navegadores Mozilla */
  -webkit-appearance: auto; /* Para navegadores Webkit */
}
.btn-color {
  background-color: var(--color-botex);
  color: var(--color-text);
  font-weight: bold;
}
.btn,
input,
option,
select,
div,
textarea,
checkbox {
  border-radius: 30px;
}
</style>
