<script setup lang="ts">
import { apiClient } from '../api/ServiceApi'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import CreateServer from '../components/modal/server/CreateServer.vue'
import DeleteServer from '../components/modal/server/DeleteServer.vue'
import EditServer from '../components/modal/server/EditServer.vue'
import { onBeforeMount, ref } from 'vue'
library.add(fas, faTwitter, faFontAwesome)

const serverData = ref<{
  id: string
  name: string
  host: string
  port: string
  capacity: string
  key: string
  proxy: string
  enabled: string
} | null>(null)
const allServers = ref()

async function populate_model_edit(
  serverId: string,
  serverName: string,
  serverHost: string,
  serverPort: string,
  serverCapacity: string,
  serverKey: string,
  serverProxy: string,
  serverEnabled: string
) {
  serverData.value = {
    id: serverId,
    name: serverName,
    host: serverHost,
    port: serverPort,
    capacity: serverCapacity,
    key: serverKey,
    proxy: serverProxy,
    enabled: serverEnabled
  }
}

async function listServers() {
  try {
    const response = await apiClient.get('/server')
    if (response['data']['statusCode'] == 200) {
      allServers.value = response['data']['allServers']
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}

onBeforeMount(async () => {
  await listServers()

  document.getElementById('deleteusermodal')?.addEventListener('show.bs.modal', (event: any) => {
    const relatedTarget = event.relatedTarget as HTMLElement | null
    const userId = relatedTarget?.dataset.userId
    const userName = relatedTarget?.dataset.userName
    const userNameField = document.getElementById('user_to_delete') as HTMLHeadingElement | null
    const userIdField = document.getElementById('user_id_to_delete') as HTMLHeadingElement | null
    // Verifique se o elemento foi encontrado
    if (
      userNameField &&
      userName !== undefined &&
      userName !== null &&
      userIdField &&
      userId !== undefined &&
      userId !== null
    ) {
      // Modifique o conteúdo do elemento
      userNameField.textContent = userName
      userIdField.textContent = userId
    }
  })
})
</script>

<template>
  <div class="overflow-auto w-100 table_div">
    <table class="table table-hover">
      <thead class="table-dark">
        <tr>
          <th scope="col" class="user-select-none">Nome</th>
          <th scope="col" class="user-select-none">Host</th>
          <th scope="col" class="user-select-none">Porta</th>
          <th scope="col" class="user-select-none">Proxy</th>
          <th scope="col" class="user-select-none">Capacidade</th>
          <th scope="col" class="user-select-none">Status</th>
          <th scope="col" class="user-select-none" style="width: 30vh">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="server in allServers" v-bind:key="server.name">
          <td>{{ server.name }}</td>
          <td>{{ server.host }}</td>
          <td>{{ server.port }}</td>
          <td>{{ server.proxy }}</td>
          <td>{{ server.capacity }}</td>
          <td>
            <div v-if="server.enabled">
              <v-btn data-toggle="tooltip" data-placement="top" title="Servidor Ativo">
                <FontAwesomeIcon :icon="fas.faMicrochip" />
              </v-btn>
            </div>
            <div v-else>
              <v-btn data-toggle="tooltip" data-placement="top" title="Servidor Inativo">
                <FontAwesomeIcon :icon="fas.faPowerOff" />
              </v-btn>
            </div>
          </td>
          <td>
            <v-btn
              class="me-2"
              data-bs-toggle="modal"
              data-bs-target="#editservermodal"
              @click="
                populate_model_edit(
                  server._id,
                  server.name,
                  server.host,
                  server.port,
                  server.capacity,
                  server.key,
                  server.proxy,
                  server.enabled
                )
              "
            >
              <FontAwesomeIcon :icon="fas.faEdit" />
            </v-btn>
            <v-btn
              data-bs-toggle="modal"
              data-bs-target="#deleteservermodal"
              @click="
                populate_model_edit(
                  server._id,
                  server.name,
                  server.host,
                  server.port,
                  server.capacity,
                  server.key,
                  server.proxy,
                  server.enabled
                )
              "
            >
              <FontAwesomeIcon :icon="fas.faTrashAlt" />
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="section p-3">
    <v-btn data-bs-toggle="modal" data-bs-target="#createservermodal"> Cadastrar Servidor </v-btn>
    <CreateServer />
    <DeleteServer :serverData="serverData" />
    <EditServer :serverData="serverData" />
  </div>
</template>

<style scoped>
.table_div {
  height: 70vh;
}
.table thead {
  position: sticky;
  top: 0;
  background-color: #b40000; /* Optional: Set a background color for the fixed header */
  z-index: 1; /* Ensure the header is on top of the content */
}
</style>
