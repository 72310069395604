<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'

async function delete_user() {
  const $toast = useToast()
  try {
    const userIdField = document.getElementById('user_id_to_delete')?.textContent
    const response = await apiClient.delete(`/user/${userIdField}`)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Usuário deletado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao deletar o usuário!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao deletar o usuário !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}
</script>
<template>
  <div
    class="modal"
    tabindex="-1"
    id="deleteusermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Deletar Usuário</h5>
          <v-btn
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja deletar o usuário:</p>
          <h5 id="user_to_delete">--</h5>
          <input id="user_id_to_delete" type="hidden" />
        </div>
        <div class="modal-footer">
          <v-btn type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</v-btn>
          <v-btn type="button" @click="delete_user()" class="btn btn-danger">
            Deletar Usuário
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
