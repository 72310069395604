import { createRouter, createWebHistory } from 'vue-router'
import DashboardAdminLayout from '@/components/layout/DashboardAdminLayout.vue'
import CustomerLayout from '@/components/layout/CustomerLayout.vue'
import LoginPage from '@/components/pages/LoginPage.vue'
import HomeView from '@/views/HomeView.vue'
import CustomerView from '@/views/CustomerView.vue'
import UsersView from '@/views/UsersView.vue'
import UserGroupView from '@/views/UserGroupView.vue'
import PlansView from '@/views/PlansView.vue'
import ProfileView from '@/views/ProfileView.vue'
import ServerView from '@/views/ServerView.vue'
import AboutView from '@/views/AboutView.vue'
import PageNotFoundView from '@/views/PageNotFoundView.vue'
import AutomationsView from '@/views/AutomationsView.vue'
import LeadSpreadSheetView from '@/views/LeadSpreadSheetView.vue'
import LeadsPanelView from '@/views/LeadsPanelView.vue'
import MultiAgentView from '@/views/MultiAgentView.vue'
import axios from 'axios'
import CustomerDashboardView from '@/views/CustomerDashboardView.vue'
import { getAPIBaseURL } from '@/api/ServiceApi'
import MarketingMassiveSender from '@/views/MarketingMassiveSender.vue'
import { jwtDecode } from 'jwt-decode'

interface PlanData {
  features: string[]
  name: string
}

interface TokenData {
  email: string
  exp: number
  iat: number
  name: string
  sub: string
  plan: PlanData
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:catchAll(.*)*',
      name: 'PageNotFound',
      component: PageNotFoundView
    },
    {
      path: '/',
      redirect: '/admin'
    },
    {
      path: '/customer',
      redirect: '/customer/dashboard',
      name: 'Customer',
      component: CustomerLayout,
      props: true,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'about',
          name: 'AboutView',
          component: AboutView
        },
        {
          path: 'marketing/sender',
          name: 'MassiveSender',
          component: MarketingMassiveSender,
          meta: { requiredFunction: 'wppmarketing' }
        },
        {
          path: 'automations/:id?',
          name: 'CustomerAutomation',
          component: AutomationsView
        },
        {
          path: 'dashboard',
          name: 'CustomerDashboard',
          component: CustomerDashboardView,
          props: true
        },
        {
          path: 'crm/chat',
          name: 'MultiAgent',
          component: MultiAgentView,
          props: true
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/admin',
      name: 'admin',
      redirect: '/admin/home',
      component: DashboardAdminLayout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'home',
          name: 'home',
          component: HomeView
        },
        {
          path: 'users',
          name: 'users',
          component: UsersView
        },
        {
          path: 'groups',
          name: 'groups',
          component: UserGroupView
        },
        {
          path: 'automations/:id?',
          name: 'automations',
          component: AutomationsView
        },
        {
          path: 'plans',
          name: 'plans',
          component: PlansView
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('@/views/AboutView.vue')
        },
        {
          path: 'customer',
          name: 'customer',
          component: CustomerView
        },
        {
          path: 'profile',
          name: 'profile',
          component: ProfileView
        },
        {
          path: 'server',
          name: 'server',
          component: ServerView
        },
        {
          path: 'leads/createspreadsheet',
          name: 'leads',
          component: LeadSpreadSheetView
        },
        {
          path: 'leads/panel',
          name: 'LeadsPanel',
          component: LeadsPanelView
        }
      ]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token')
  const apiClient = axios.create({
    baseURL: getAPIBaseURL(),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`

  if (token) {
    const tokenData = jwtDecode<TokenData>(String(token))

    if (to.meta.requiredFunction) {
      const hasFunction = tokenData.plan.features.includes(String(to.meta.requiredFunction))

      if (!hasFunction) {
        console.log('Função necessária não encontrada no plano do usuário')
        return next('/') // Redirecionar para uma página de acesso negado ou outra página
      }
    }
  }

  if (to.meta.requiresAuth) {
    if (!token) {
      next('/login')
      return true
    }
    try {
      const check_auth = await apiClient.get('/auth/profile')
      if (check_auth.status === 200) {
        const user = check_auth.data
        localStorage.setItem('userType', user.type)
        if (to.path.startsWith('/admin') && user.type !== 'user') {
          next('/customer/dashboard') // Redireciona clientes para a área do cliente
        } else if (to.path.startsWith('/customer') && user.type !== 'customer') {
          next('/admin/home') // Redireciona administradores para a área de administração
        } else {
          next()
        }
      } else {
        localStorage.removeItem('token')
        next('/login')
      }
    } catch (err) {
      localStorage.removeItem('token')
      next('/login')
    }
  } else {
    next()
  }
})

export default router
