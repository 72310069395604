<script setup lang="ts">
import { apiClient } from '../api/ServiceApi'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { ref } from 'vue'

library.add(fas, faTwitter, faFontAwesome)
declare var window: any
const searchText = ref('')
const searchResult = ref()
const fileName = ref('')
const loading = ref(false)
const showModal = ref(false)

function clearNumber(numero: string | null) {
  // Remove todos os caracteres que não são dígitos
  if (numero === null) {
    return { number: 'Sem dados', isMobile: false }
  }
  const cleanedNumber = numero.replace(/\D/g, '')
  // Verifica se o número tem o formato de celular brasileiro
  const celularRegex =
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:(?:9\d{4})|(?:9\d{4}-?\d{4}))$/

  // Testa se o número corresponde ao formato de celular
  const isMobile = celularRegex.test(cleanedNumber)

  return { number: cleanedNumber, isMobile }
}

async function search_data() {
  showModal.value = true // Exibe o modal antes de iniciar a pesquisa
  loading.value = true

  fileName.value = searchText.value.replace(/\s+/g, '-')
  try {
    const response = await apiClient.get(`/lead/${searchText.value}`)
    if (response['data']['statusCode'] == 200) {
      searchResult.value = response['data']['Lead']['data']
      console.log(response['data']['Lead']['data'])
    } else {
      console.log('Houve um erro')
    }
    loading.value = false // Define loading como falso após receber a resposta
    showModal.value = false // Oculta o modal quando a pesquisa termina
  } catch (e) {
    loading.value = false // Define loading como falso após receber a resposta
    showModal.value = false // Oculta o modal quando a pesquisa termina
    console.log('Erro interno', e)
    return true
  }
}
function deleteResult(index: number) {
  searchResult.value.splice(index, 1)
}

async function exportToExcel() {
  // Verifica se há dados para exportar
  if (!searchResult.value || searchResult.value.length === 0) {
    console.log('Não há dados para exportar')
    return
  }

  // Cria uma array para os dados da planilha
  const data = [['Nome', 'Contato', 'Endereço']]

  // Adiciona os dados de cada registro da searchResult à array
  searchResult.value.forEach((item: { name: string; phone: string; address: string }) => {
    data.push([item.name, clearNumber(item.phone)['number'], item.address])
  })
  // Cria uma nova workbook e adiciona a planilha
  const workbook = window.XLSX.utils.book_new()
  const worksheet = window.XLSX.utils.aoa_to_sheet(data)
  window.XLSX.utils.book_append_sheet(workbook, worksheet, 'Planilha')

  // Converte a workbook para um blob e inicia o download
  const excelBlob = window.XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([excelBlob], { type: 'application/octet-stream' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `botex_pesquisa-${fileName.value}.xlsx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
</script>

<template>
  <div class="input-group mb-3">
    <input
      type="text"
      class="form-control"
      placeholder="Digite o termo para busca de locais"
      v-model="searchText"
      aria-label="Busca de locais"
      aria-describedby="basic-addon2"
    />
    <div class="input-group-append">
      <!-- Modal -->
      <div v-if="loading" class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <v-btn v-if="!loading" class="btn btn-success" type="button" @click="search_data()">
        Buscar
      </v-btn>
    </div>
  </div>

  <div class="overflow-auto w-100 table_div">
    <table class="table table-hover">
      <thead class="table-dark">
        <tr>
          <th scope="col" class="user-select-none">Nome</th>
          <th scope="col" class="user-select-none">Contato</th>
          <th scope="col" class="user-select-none">Endereço</th>
          <th scope="col" class="user-select-none" style="width: 30vh">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(result, index) in searchResult" v-bind:key="result.name">
          <td>{{ result.name }}</td>
          <td v-if="!result.phone">{{ result.phone }}</td>
          <td v-else>{{ clearNumber(result.phone)['number'] }}</td>
          <td>{{ result.address }}</td>

          <td>
            <v-btn type="button" class="btn btn-danger" @click="deleteResult(index)">
              <FontAwesomeIcon :icon="fas.faTrashAlt" />
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="section p-3">
    <v-btn type="button" class="btn btn-primary" @click="exportToExcel()">Salvar Planilha</v-btn>
  </div>
</template>

<style scoped>
.table_div {
  height: 70vh;
}
.table thead {
  position: sticky;
  top: 0;
  background-color: #b40000; /* Optional: Set a background color for the fixed header */
  z-index: 1; /* Ensure the header is on top of the content */
}

.circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #007bff; /* Cor de fundo do círculo */
  border-radius: 50%; /* Bordas arredondadas para criar um círculo */
  color: #fff; /* Cor do ícone */
}
.btn,
input,
option,
select {
  border-radius: 30px;
}
</style>
