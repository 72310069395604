<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'
import { ref, watch } from 'vue'

const props = defineProps<{
  serverData: {
    id: string
    name: string
    host: string
    port: string
    capacity: string
    key: string
    proxy: string
    enabled: string
  } | null
}>()

const serverID = ref('')
const serverName = ref('')
const serverHost = ref('')
const serverPort = ref('')
const serverCapacity = ref('')
const serverKey = ref('')
const serverEnabled = ref('')
const serverProxy = ref('')

watch(
  () => props.serverData,
  (newValue) => {
    if (newValue) {
      serverID.value = newValue.id
      serverName.value = newValue.name
      serverHost.value = newValue.host
      serverPort.value = newValue.port
      serverCapacity.value = newValue.capacity
      serverKey.value = newValue.key
      serverProxy.value = newValue.proxy
      serverEnabled.value = newValue.enabled
    }
  }
)

async function editServer() {
  const $toast = useToast()
  try {
    console.log({
      name: serverName.value,
      host: serverHost.value,
      port: serverPort.value,
      capacity: serverCapacity.value,
      key: serverKey.value,
      proxy: serverProxy.value,
      enabled: serverEnabled.value
    })
    const response = await apiClient.put(`/server/${serverID.value}`, {
      name: serverName.value,
      host: serverHost.value,
      port: serverPort.value,
      capacity: serverCapacity.value,
      key: serverKey.value,
      proxy: serverProxy.value,
      enabled: serverEnabled.value
    })
    if (response['data']['statusCode'] == 200) {
      $toast.success('Servidor editado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao editar o Servidor!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao editar Servidor !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
    return true
  }
}
</script>
<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="editservermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <!-- Altera para modal-lg para tornar o modal mais largo -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Editar Servidor</h5>
          <v-btn
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <form @submit.prevent="editServer">
            <div class="row">
              <div class="mb-3">
                <label for="nome" class="form-label">Nome:</label>
                <input
                  type="text"
                  class="form-control"
                  id="nome"
                  placeholder="Digite o nome do servidor"
                  v-model="serverName"
                />
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="host" class="form-label">Host:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="host"
                    placeholder="Digite o host do servidor"
                    v-model="serverHost"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="port" class="form-label">Porta:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="port"
                    placeholder="Porta da API"
                    v-model="serverPort"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="capacity" class="form-label">Capacidade de Bots:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="capacity"
                    placeholder="Capacidade de Bots"
                    v-model="serverCapacity"
                  />
                </div>
              </div>
              <div class="col-md-9">
                <div class="mb-9">
                  <label for="api_key" class="form-label">Chave da API:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="api_key"
                    placeholder="Digite a chave daAPI"
                    v-model="serverKey"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="status" class="form-label">Proxy:</label>
                  <select class="form-select" id="status" v-model="serverProxy">
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>
              <div class="mb-3">
                <label for="status" class="form-label">Status:</label>
                <select class="form-select" id="status" v-model="serverEnabled">
                  <option value="undefined">Selecione o status</option>
                  <option value="true">Ativo</option>
                  <option value="false">Inativo</option>
                </select>
              </div>
              <v-btn type="submit" class="btn btn-primary">Salvar Alterações</v-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
