import axios from 'axios'

export function getToken() {
  return localStorage.getItem('token')
}

export function getAPIBaseURL() {
  if (import.meta.env.PROD) {
    return window.location.origin + '/api'
  } else {
    return `http://${import.meta.env.VITE_BOTEX_API_HOST}:${import.meta.env.VITE_BOTEX_API_PORT}`
  }
}

export const apiClient = axios.create({
  baseURL: getAPIBaseURL(),
  headers: {
    'Content-Type': 'application/json'
  }
})

// Adicionando um interceptor para todas as requisições
apiClient.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export function whatsappAPIManager(proxy: boolean, apiHost: string, apiPort: number) {
  let urlAPI: string
  if (proxy && import.meta.env.PROD) {
    urlAPI = `${window.location.origin}/whatsapp/${apiHost}`
  } else {
    urlAPI = `http://${apiHost}:${apiPort}`
  }
  return urlAPI
}

export const whatsappURLManager = (
  proxy: boolean,
  apiHost: string,
  apiPort: number,
  apiKey: string
) =>
  axios.create({
    baseURL: whatsappAPIManager(proxy, apiHost, apiPort),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`
    }
  })
