<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { ref, watch } from 'vue'
import { apiClient } from '@/api/ServiceApi'

const props = defineProps<{
  userData: {
    id: string
    name: string
    email: string
    password: string
    enabled: string
  } | null
}>()

const userID = ref('')
const userName = ref('')
const userEmail = ref('')
const userPassword = ref('')
const userEnabled = ref('')

watch(
  () => props.userData,
  async (newValue) => {
    if (newValue) {
      userID.value = newValue.id
      userName.value = newValue.name
      userEmail.value = newValue.email
      userPassword.value = newValue.password
      userEnabled.value = newValue.enabled
    }
  }
)
async function edit_user() {
  const $toast = useToast()
  try {
    const response = await apiClient.post(`/user/${userID.value}`, {
      name: userName.value,
      email: userEmail.value,
      password: userPassword.value,
      enabled: userEnabled.value
    })
    if (response['data']['statusCode'] == 200) {
      $toast.success('Usuário criado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao criar o usuário!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao criar usuário !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)

    return true
  }
}
</script>
<template>
  <div
    class="modal"
    id="editusermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="edituser">Editar Usuário</h1>
          <v-btn
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <form @submit.prevent="edit_user">
            <div class="mb-3">
              <label class="form-label" for="edit_user_full_name">Nome</label>
              <input
                type="text"
                class="form-control"
                id="edit_user_full_name"
                placeholder="Nome Completo"
                v-model="userName"
              />
            </div>
            <div class="mb-3">
              <label for="edit_user_mail" class="form-label">Email</label>
              <input
                type="text"
                class="form-control"
                id="edit_user_mail"
                placeholder="Email"
                v-model="userEmail"
              />
            </div>
            <div class="mb-3">
              <label for="edituser_password" class="form-label">Senha</label>
              <input
                type="password"
                class="form-control"
                id="edituser_password"
                placeholder="Senha"
                v-model="userPassword"
              />
            </div>
            <div class="mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="edit_user_status"
                v-model="userEnabled"
                checked
              />
              <label class="form-check-label" for="edit_user_status"> Habilitar usuário </label>
            </div>

            <div class="modal-footer">
              <v-btn type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Fechar
              </v-btn>
              <v-btn type="submit" class="btn btn-primary">Editar</v-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
