<script setup lang="ts">
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { apiClient } from '@/api/ServiceApi'
import { useToast } from 'vue-toast-notification'
import router from '@/router'

library.add(fas, faTwitter, faFontAwesome)

interface LoginData {
  email: string
  password: string
}

interface LeadData {
  name: string
  email: string
  phone: string
  status: string
}

const login_data: LoginData = {
  email: '',
  password: ''
}

const lead_data: LeadData = {
  name: '',
  email: '',
  phone: '',
  status: 'created'
}

function change_form(val: boolean): void {
  const container = document.querySelector('.container') as HTMLElement | null
  if (container) {
    if (val) {
      container.classList.remove('sign-up-mode')
    } else {
      container.classList.add('sign-up-mode')
    }
  } else {
    console.error('.container not found')
  }
}

async function login(): Promise<void> {
  const $toast = useToast()
  try {
    const response = await apiClient.post('/auth/login', login_data)
    if (response.data.statusCode === 200) {
      $toast.success('Login Efetuado!', { duration: 5000, position: 'top-right' })
      localStorage.setItem('token', response.data.data)
      await router.push('/admin')
    } else {
      $toast.error('Login ou Senha incorreta!', { duration: 5000, position: 'top-right' })
    }
  } catch (e) {
    $toast.error('Erro interno ao fazer login!', { duration: 5000, position: 'top-right' })
  }
}

async function createLead(): Promise<void> {
  const $toast = useToast()
  try {
    const response = await apiClient.post('/lead', lead_data)
    if (response.data.statusCode === 200) {
      $toast.success('Pré cadastro efetuado com sucesso!', {
        duration: 5000,
        position: 'top-right'
      })
      localStorage.setItem('token', response.data.data)
      await router.push('/admin')
    } else {
      $toast.error('Falha ao criar o pré cadastro!', { duration: 5000, position: 'top-right' })
    }
  } catch (e) {
    $toast.error('Erro interno ao criar o pré cadastro!', { duration: 5000, position: 'top-right' })
  }
}
</script>

<template>
  <div class="container">
    <div class="forms-container">
      <div class="signin-signup">
        <form @submit.prevent="login" class="sign-in-form">
          <h2 class="title">Login</h2>
          <div class="input-field">
            <i><FontAwesomeIcon :icon="fas.faUser" /></i>
            <input
              type="text"
              id="email"
              name="email"
              autocomplete="false"
              placeholder="Email"
              v-model="login_data.email"
            />
          </div>
          <div class="input-field">
            <i><FontAwesomeIcon :icon="fas.faLock" /></i>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Senha"
              v-model="login_data.password"
            />
          </div>
          <input type="submit" value="Login" class="btn solid" />
          <p class="forgot-password">Esqueci minha senha</p>
        </form>
        <form @submit.prevent="createLead" class="sign-up-form">
          <h4 class="title">Pré Cadastro</h4>
          <div class="input-field">
            <i><FontAwesomeIcon :icon="fas.faUser" /></i>
            <input
              type="text"
              name="name"
              id="name"
              autocomplete="false"
              placeholder="Nome"
              v-model="lead_data.name"
            />
          </div>
          <div class="input-field">
            <i><FontAwesomeIcon :icon="fas.faEnvelope" /></i>
            <input
              type="email"
              name="contactemail"
              id="contactemail"
              autocomplete="false"
              placeholder="Email"
              v-model="lead_data.email"
            />
          </div>
          <div class="input-field">
            <i><FontAwesomeIcon :icon="fas.faPhone" /></i>
            <input
              type="text"
              placeholder="Número de telefone para contato"
              v-model="lead_data.phone"
              id="phone"
              name="phone"
              autocomplete="false"
            />
          </div>
          <input type="submit" class="btn" value="Cadastro" />
        </form>
      </div>
    </div>

    <div class="panels-container">
      <div class="panel left-panel">
        <div class="content">
          <h2 class="newcustomer">Novo por aqui ?</h2>
          <p>Crie seu Assistente Virtual para atender o WhatsApp do seu negócio</p>
          <v-btn class="btn-green" id="sign-up-btn" @click="change_form(false)">
            Teste grátis
          </v-btn>
        </div>
        <img src="../../assets/login.svg" class="image" alt="" />
      </div>
      <div class="panel right-panel">
        <div class="content">
          <h3>Já é nosso cliente ?</h3>
          <p>
            É hora de explorar mais! Faça login agora para desfrutar de vantagens exclusivas e
            personalizadas
          </p>
          <v-btn class="btn transparent" id="sign-in-btn" @click="change_form(true)">Login</v-btn>
        </div>
        <img src="../../assets/register.svg" class="image" alt="" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  color: var(--color-botex);
  font-family: 'Comfortaa', sans-serif;
}
.newcustomer {
  font-weight: 700;
  color: var(--color-text);
  font-family: 'Comfortaa', sans-serif;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 5px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--color-text-black);
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}
.btn-green {
  width: 150px;
  background-color: var(--color-green);
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}
.btn {
  width: 150px;
  background-color: var(--color-botex);
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 900;
}

.btn:hover {
  background-color: var(--color-botex-hover);
}
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  /* top: 0; */
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container:before {
  content: '';
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, var(--color-botex) 0%, var(--color-botex) 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 140px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  html {
    width: 100%;
    height: 100%;
  }
  .container {
    position: absolute;
    /* min-height: 800px; */
    height: 100vh;
    overflow: hidden;
  }
  .signin-signup {
    width: 100%;
    top: 75%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 3;
    margin-bottom: 70px;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 65%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 35%;
    right: initial;
  }

  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  /* .container {
    padding: 1.5rem;
  } */

  .container:before {
    bottom: 65%;
    left: 50%;
  }

  .container.sign-up-mode:before {
    bottom: 35%;
    left: 50%;
  }
}
</style>
