<template>
  <v-container class="fill-height d-flex justify-center align-center">
    <v-card class="pa-md-4" width="50%" height="50vh">
      <v-card-text>
        <p class="text-center text-h4 font-weight-black">BotEx</p>

        <p class="text-center">Versão: 2.0</p>

        <div class="text-center text-medium-emphasis">...</div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
