<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'

async function delete_plan() {
  const $toast = useToast()
  try {
    const planIdField = document.getElementById('plan_id_to_delete')?.textContent
    const response = await apiClient.delete(`/plan/${planIdField}`)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Plano deletado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao deletar o plano!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao deletar o plano !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}
</script>
<template>
  <div
    class="modal"
    tabindex="-1"
    id="deleteplanmodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Deletar Plano</h5>
          <v-btn type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></v-btn>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja deletar o plano:</p>
          <h5 id="plan_to_delete">--</h5>
          <input id="plan_id_to_delete" type="hidden" />
        </div>
        <div class="modal-footer">
          <v-btn type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</v-btn>
          <v-btn type="button" @click="delete_plan()" class="btn btn-danger">Deletar Plano</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
