<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'
import { onMounted, ref, watch } from 'vue'
const allPlans = ref()
const allServers = ref()
const $toast = useToast()
interface Server {
  proxy: boolean
  host: string
  port: number
  key: string
}
const props = defineProps<{
  customerData: {
    id: string
    name: string
    email: string
    cpf_cnpj: string
    login: string
    password: string
    phone: string
    plan: string
    server: Server
    enabled: string
  } | null
}>()

const customerID = ref('')
const customerName = ref('')
const customerEmail = ref('')
const customerCpf_cnpj = ref('')
const customerPhone = ref('')
const customerPlan = ref('')
const customerServer = ref<Server | null>(null)
const customerEnabled = ref('')
const customerLogin = ref('')
const customerPassword = ref('')
watch(
  () => props.customerData,
  async (newValue) => {
    if (newValue) {
      customerID.value = newValue.id
      customerName.value = newValue.name
      customerEmail.value = newValue.email
      customerCpf_cnpj.value = newValue.cpf_cnpj
      customerPhone.value = newValue.phone
      customerPlan.value = newValue.plan
      customerServer.value = newValue.server
      customerEnabled.value = newValue.enabled
      customerLogin.value = newValue.login
      customerPassword.value = newValue.password
      await list_server() // Chama a função para obter os servidores
      await list_plan() // Chama a função para obter os planos
    }
  }
)

async function maskCpfCNPJ() {
  let v = customerCpf_cnpj.value.replace(/\D/g, '')
  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    v = v.replace(/^(\d{2})(\d)/, '$1.$2')
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
    v = v.replace(/(\d{4})(\d)/, '$1-$2')
  }
  customerCpf_cnpj.value = v
}

async function maskTelefone() {
  let telefone = customerPhone.value.replace(/\D/g, '') // Remove todos os caracteres não numéricos
  if (telefone.length === 11) {
    // Formato para telefone celular com DDD
    telefone = telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
  } else if (telefone.length === 10) {
    // Formato para telefone fixo com DDD
    telefone = telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
  } else if (telefone.length === 9) {
    // Formato para celular sem DDD
    telefone = telefone.replace(/^(\d{5})(\d{4})$/, '$1-$2')
  } else if (telefone.length === 8) {
    // Formato para telefone fixo sem DDD
    telefone = telefone.replace(/^(\d{4})(\d{4})$/, '$1-$2')
  }
  customerPhone.value = telefone
}
async function list_server() {
  try {
    const response = await apiClient.get('/server')
    if (response['data']['statusCode'] == 200) {
      allServers.value = response['data']['allServers']
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}
async function list_plan() {
  try {
    const response = await apiClient.get('/plan')
    if (response['data']['statusCode'] == 200) {
      allPlans.value = response['data']['allPlans']
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}
onMounted(async () => {
  await list_plan()
  await list_server()
})

async function edit_customer() {
  try {
    const response = await apiClient.put(`/customer/${customerID.value}`, {
      name: customerName.value,
      email: customerEmail.value,
      cpf_cnpj: customerCpf_cnpj.value,
      login: customerLogin.value,
      password: customerPassword.value,
      phone: customerPhone.value,
      plan: customerPlan.value,
      server: customerServer.value,
      enabled: customerEnabled.value
    })
    console.log(response)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Cliente alterado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao alterar o cliente!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Ocorreu um erro ao alterar o cliente !', {
      duration: 5000,
      position: 'top-right'
    })
    setTimeout(() => {
      location.reload()
    }, 1000)

    return true
  }
}
</script>
<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="editcustomermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <!-- Altera para modal-lg para tornar o modal mais largo -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar cadastro de cliente</h5>
          <v-btn
            type="button"
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <!-- Formulário de cadastro de cliente -->
          <form id="formCadastroCliente" @submit.prevent="edit_customer">
            <div class="row">
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="nome" class="form-label">Nome:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nome"
                    placeholder="Digite o nome do cliente"
                    v-model="customerName"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="email" class="form-label">Email:</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Digite o email do cliente"
                    v-model="customerEmail"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="cpfCnpj" class="form-label">CPF/CNPJ:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cpfCnpj"
                    placeholder="Digite o CPF ou CNPJ"
                    v-model="customerCpf_cnpj"
                    @keyup="maskCpfCNPJ"
                    maxlength="18"
                    required
                  />
                  <div id="cpfCnpjError" class="invalid-feedback">
                    Por favor, insira um CPF ou CNPJ válido.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="telefone" class="form-label">Telefone:</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="telefone"
                    placeholder="Digite o telefone do cliente"
                    v-model="customerPhone"
                    @keyup="maskTelefone"
                    maxlength="15"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="plano" class="form-label">Plano:</label>
                  <select class="form-select" id="plano" v-model="customerPlan" required>
                    <option value="">Selecione um plano</option>
                    <option
                      v-for="plan in allPlans"
                      :key="plan._id"
                      :value="plan._id"
                      :selected="plan._id === customerPlan"
                    >
                      {{ plan.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="server" class="form-label">Servidor:</label>
                  <select class="form-select" id="server" v-model="customerServer" required>
                    <option value="">Selecione um servidor</option>
                    <option v-for="server in allServers" :key="server._id" :value="server._id">
                      {{ server.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="login" class="form-label">Login:</label>
                  <input
                    class="form-control"
                    id="login"
                    placeholder="Digite o login do cliente"
                    v-model="customerLogin"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="password" class="form-label">Senha:</label>
                  <input
                    class="form-control"
                    id="password"
                    placeholder="Digite uma senha para o cliente"
                    v-model="customerPassword"
                    required
                  />
                </div>
              </div>
              <div class="mb-3">
                <label for="status" class="form-label">Status:</label>
                <select class="form-select" id="status" v-model="customerEnabled" required>
                  <option value="true">Ativo</option>
                  <option value="false">Inativo</option>
                </select>
              </div>
            </div>
            <v-btn type="submit" class="btn btn-primary">Editar Cadastro</v-btn>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn,
input,
option,
select,
div {
  border-radius: 30px;
}
</style>
