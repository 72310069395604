<template>
  <div class="d-flex flex-wrap ga-3 w-100 h-100 pa-5">
    <div class="h-50 w-33">
      <v-chart :option="generateCustomerDataChart(activeCustomers, inactiveCustomers)" autoresize />
    </div>
    <div class="h-50 w-33" v-for="server in customersByServer" :key="server.name">
      <v-chart
        :option="generateServerDataChart(server.usedSpace, server.freeSpace, server.name)"
        autoresize
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { apiClient } from '../api/ServiceApi'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import VChart from 'vue-echarts'

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent])

// Definir a interface para o tipo Customer
interface Customer {
  enabled: boolean
  serverId: number
  server: Server
  // Adicione outras propriedades conforme necessário
}

// Definir a interface para o tipo Server
interface Server {
  _id: number
  name: string
  capacity: number
  highUsage?: boolean // Propriedade para indicar alta utilização
  fullUsage?: boolean // Propriedade para indicar capacidade cheia
  // Adicione outras propriedades conforme necessário
}

const allCustomers = ref<Customer[]>([])
const allServers = ref<Server[]>([])
const activeCustomers = ref(0)
const inactiveCustomers = ref(0)

async function listServers() {
  try {
    const response = await apiClient.get('/server')
    if (response.data.statusCode == 200) {
      allServers.value = response.data.allServers
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}

async function listCustomers() {
  try {
    const response = await apiClient.get('/customer')
    if (response.data.statusCode === 200) {
      allCustomers.value = response.data.allCustomers
      activeCustomers.value = allCustomers.value.filter((customer) => customer.enabled).length
      inactiveCustomers.value =
        allCustomers.value.filter((customer) => !customer.enabled).length || 0
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}

// Computed property para contar clientes por servidor
const customersByServer = computed(() => {
  return allServers.value.map((server) => {
    const customersOnServer = allCustomers.value.filter(
      (customer) => customer.server._id === server._id
    )
    const freeSpace = server.capacity - customersOnServer.length // Conta apenas os clientes associados ao servidor atual
    const usedSpace = customersOnServer.length
    return {
      ...server,
      freeSpace,
      usedSpace
    }
  })
})

onBeforeMount(async () => {
  await listServers()
  await listCustomers()
})

function generateCustomerDataChart(_activeCustomers: number, _inactiveCustomers: number) {
  return {
    title: {
      text: 'Clientes',
      left: 'center'
    },
    tooltip: {
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      top: '10%'
    },
    series: [
      {
        name: 'Clientes cadastrados',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          {
            value: _activeCustomers,
            name: `${_activeCustomers} Ativos`,
            itemStyle: { color: '#5170ff' }
          },
          {
            value: _inactiveCustomers,
            name: `${_inactiveCustomers} Inativos`,
            itemStyle: { color: '#FF0000' }
          }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
}

function generateServerDataChart(_usedSpaces: number, _freeSpaces: number, _serverName: string) {
  return {
    title: {
      text: _serverName,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      top: '10%'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          {
            value: _usedSpaces,
            name: `${_usedSpaces} Bots Ativos`,
            itemStyle: { color: '#5170ff' }
          },
          {
            value: _freeSpaces,
            name: `${_freeSpaces} Bots Disponíveis`,
            itemStyle: { color: '#ff66c4' }
          }
        ]
      }
    ]
  }
}
</script>

<style scoped></style>
