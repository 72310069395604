<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'
import { onMounted, ref } from 'vue'
const allPlans = ref()
const allServers = ref()

async function list_server() {
  try {
    const response = await apiClient.get('/server')
    if (response['data']['statusCode'] == 200) {
      allServers.value = response['data']['allServers']
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}
async function list_plan() {
  try {
    const response = await apiClient.get('/plan')
    if (response['data']['statusCode'] == 200) {
      allPlans.value = response['data']['allPlans']
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}
onMounted(async () => {
  await list_plan()
  await list_server()
})
const customer_data = ref({
  name: '',
  email: '',
  cpf_cnpj: '',
  login: '',
  password: '',
  phone: '',
  plan: '',
  server: '',
  enabled: ''
})

async function maskCpfCNPJ() {
  let v = customer_data.value.cpf_cnpj.replace(/\D/g, '')
  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    v = v.replace(/^(\d{2})(\d)/, '$1.$2')
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
    v = v.replace(/(\d{4})(\d)/, '$1-$2')
  }
  customer_data.value.cpf_cnpj = v
}

async function maskTelefone() {
  let telefone = customer_data.value.phone.replace(/\D/g, '') // Remove todos os caracteres não numéricos
  if (telefone.length === 11) {
    // Formato para telefone celular com DDD
    telefone = telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
  } else if (telefone.length === 10) {
    // Formato para telefone fixo com DDD
    telefone = telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
  } else if (telefone.length === 9) {
    // Formato para celular sem DDD
    telefone = telefone.replace(/^(\d{5})(\d{4})$/, '$1-$2')
  } else if (telefone.length === 8) {
    // Formato para telefone fixo sem DDD
    telefone = telefone.replace(/^(\d{4})(\d{4})$/, '$1-$2')
  }
  customer_data.value.phone = telefone
}

async function create_customer() {
  const $toast = useToast()
  try {
    const response = await apiClient.post(`/customer`, customer_data.value)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Cliente criado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao criar o cliente!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    console.log(e)
    $toast.error('Erro interno ao criar o cliente !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)

    return true
  }
}
</script>
<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="createcustomermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <!-- Altera para modal-lg para tornar o modal mais largo -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Cadastro de Cliente</h5>
          <v-btn
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <!-- Formulário de cadastro de cliente -->
          <form id="formCadastroCliente" @submit.prevent="create_customer">
            <div class="row">
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="nome" class="form-label">Nome:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nome"
                    placeholder="Digite o nome do cliente"
                    v-model="customer_data.name"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="email" class="form-label">Email:</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Digite o email do cliente"
                    v-model="customer_data.email"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="cpfCnpj" class="form-label">CPF/CNPJ:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cpfCnpj"
                    placeholder="Digite o CPF ou CNPJ"
                    v-model="customer_data.cpf_cnpj"
                    @keyup="maskCpfCNPJ"
                    maxlength="18"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="telefone" class="form-label">Telefone:</label>
                  <input
                    type="tel"
                    class="form-control"
                    id="telefone"
                    placeholder="Digite o telefone do cliente"
                    v-model="customer_data.phone"
                    @keyup="maskTelefone"
                    maxlength="15"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="plano" class="form-label">Plano:</label>
                  <select class="form-select" id="plano" v-model="customer_data.plan" required>
                    <option value="">Selecione um plano</option>
                    <option v-for="plan in allPlans" :key="plan._id" :value="plan._id">
                      {{ plan.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="server" class="form-label">Servidor:</label>
                  <select class="form-select" id="server" v-model="customer_data.server" required>
                    <option value="">Selecione um servidor</option>
                    <option v-for="server in allServers" :key="server._id" :value="server._id">
                      {{ server.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="login" class="form-label">Login:</label>
                  <input
                    class="form-control"
                    id="login"
                    placeholder="Digite o login do cliente"
                    v-model="customer_data.login"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="password" class="form-label">Senha:</label>
                  <input
                    class="form-control"
                    id="password"
                    placeholder="Digite uma senha para o cliente"
                    v-model="customer_data.password"
                    required
                  />
                </div>
              </div>
              <div class="mb-3">
                <label for="status" class="form-label">Status:</label>
                <select class="form-select" id="status" v-model="customer_data.enabled" required>
                  <option selected value="true">Ativo</option>
                  <option value="false">Inativo</option>
                </select>
              </div>
            </div>
            <v-btn type="submit" class="btn btn-primary">Cadastrar</v-btn>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.btn,
input,
option,
select,
div {
  border-radius: 30px;
}
.btn-primary {
  background-color: var(--color-botex);
  color: var(--color-text);
  border: none;
}
</style>
