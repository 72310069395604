<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { ref, watch } from 'vue'
import { apiClient } from '@/api/ServiceApi'

const props = defineProps<{
  automationData: {
    id: string
    response: string
  } | null
}>()

const automationID = ref('')
const automationResponse = ref('')
watch(
  () => props.automationData,
  async (newValue) => {
    if (newValue) {
      automationID.value = newValue.id
      automationResponse.value = newValue.response
    }
  }
)

async function deleteResponse() {
  const $toast = useToast()
  try {
    const response = await apiClient.delete(`/automation/${automationID.value}`)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Resposta deletada com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao deletar a resposta!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao deletar a resposta !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}
</script>
<template>
  <div
    class="modal"
    tabindex="-1"
    id="deleteautomationmodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Deletar resposta do bot</h5>
          <v-btn type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></v-btn>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja deletar a resposta:</p>
          <h5>{{ automationResponse }}</h5>
        </div>
        <div class="modal-footer">
          <v-btn type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</v-btn>
          <v-btn type="button" @click="deleteResponse()" class="btn btn-danger">
            Deletar Resposta
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
