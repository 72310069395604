<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'
import { ref, watch } from 'vue'

const props = defineProps<{
  serverData: {
    id: string
    name: string
    host: string
    port: string
    capacity: string
    key: string
    proxy: string
    enabled: string
  } | null
}>()
const serverID = ref('')
const serverName = ref('')
watch(
  () => props.serverData,
  async (newValue) => {
    if (newValue) {
      serverID.value = newValue.id
      serverName.value = newValue.name
    }
  }
)

async function deleteServer() {
  const $toast = useToast()
  try {
    const response = await apiClient.delete(`/server/${serverID.value}`)

    if (response['data']['statusCode'] == 200) {
      $toast.success('Plano deletado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao deletar o plano!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao deletar o plano !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}
</script>
<template>
  <div
    class="modal"
    tabindex="-1"
    id="deleteservermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Deletar Servidor</h5>
          <v-btn
            type="button"
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja deletar o servidor:</p>
          <p>
            {{ serverName }}
          </p>
          <input id="plan_id_to_delete" type="hidden" />
        </div>
        <div class="modal-footer">
          <v-btn type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</v-btn>
          <v-btn type="button" @click="deleteServer()" class="btn btn-danger"
            >Deletar Servidor</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
