<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="whatsappconnectionmodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    :style="{
      'background-image': `url(${backgroundImage})`,
      'background-size': 'cover',
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      height: '100vh',
      width: '100vw'
    }"
    v-modal-shown="onModalShown"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Gerenciar conexão com WhatsApp</h5>
          <v-btn
            icon="mdi:mdi-close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></v-btn>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <!-- Adiciona a classe justify-content-center para alinhar o conteúdo no centro -->
            <!-- Ajusta a largura da coluna -->
            <div class="card text-center p-2" style="width: 20rem">
              <img
                v-if="qrCodeDataURL"
                :src="qrCodeDataURL"
                alt="QR Code"
                style="width: 100%; height: 300px"
                class="rounded"
              />
              <a
                v-if="qrCodeDataURL"
                :href="qrCodeDataURL"
                :download="`${customerName}_QRCode.png`"
                class="btn btn-primary mt-2"
                >Baixar QR Code</a
              >
              <img
                v-else
                src="../../../assets/alt_img_whtatsapp_qrcode.png"
                alt="QR Code"
                style="width: 100%; height: 300px"
                class="rounded"
              />
              <v-progress-linear
                color="#5170ff"
                bg-color="#ff66c4"
                bg-opacity="40"
                indeterminate
                height="30"
                rounded="5"
                v-if="showSpinner"
              ></v-progress-linear>
              <div class="card-body">
                <p class="card-text small">
                  Utilize o botão abaixo para gerar o QRcode para conectar seu WhatsApp, escaneie o
                  código em no máximo 30 segundos, caso esse periodo seja ultrapassado, clique
                  novamente em Gerar QR Code.
                </p>
                <div class="row text-center">
                  <div class="col">
                    <h5>Status do Bot</h5>
                  </div>
                </div>
                <div v-if="clientOnline === 'nodata'" class="col alert alert-dark" role="alert">
                  <p class="small">Sem informações, problemas na conexão</p>
                </div>
                <div v-else-if="clientOnline" class="col alert alert-success" role="alert">
                  <FontAwesomeIcon :icon="fas.faUserSlash" />
                  <p class="small">Conectado</p>
                </div>
                <div v-else class="col alert alert-danger" role="alert">
                  <FontAwesomeIcon :icon="fas.faUserSlash" />
                  <p class="small">Desconectado</p>
                </div>
                <div class="row p-1">
                  <v-btn class="btn btn-success" @click="generateQRCode">Gerar QR Code</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import QRCode from 'qrcode'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { whatsappURLManager } from '@/api/ServiceApi'
library.add(fas, faTwitter, faFontAwesome, faWhatsapp)

interface Server {
  proxy: boolean
  host: string
  port: number
  key: string
}

interface CustomerData {
  id: string
  name: string
  email: string
  cpf_cnpj: string
  phone: string
  plan: string
  server: Server
  enabled: string
}

const qrCodeDataURL = ref('')
const showModal = ref(false)
const showSpinner = ref(false)
const backgroundImage = ref('/background-whatsapp.png')

const props = defineProps<{
  customerData: CustomerData | null
}>()

const customerID = ref('')
const customerName = ref('')
const customerEmail = ref('')
const customerCpfCnpj = ref('')
const customerPhone = ref('')
const customerPlan = ref('')
const customerServer = ref<Server | null>(null)
const customerEnabled = ref('')

const clientOnline = ref('')
watch(
  () => props.customerData,
  async (newValue) => {
    if (newValue) {
      customerID.value = newValue.id
      customerName.value = newValue.name
      customerEmail.value = newValue.email
      customerCpfCnpj.value = newValue.cpf_cnpj
      customerPhone.value = newValue.phone
      customerPlan.value = newValue.plan
      customerServer.value = newValue.server
      customerEnabled.value = newValue.enabled
    }
  }
)

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

//função para verificação ativa do status do bot

const MAX_EXECUTION_TIME = 3 * 60 * 1000 // 3 minutos em milissegundos
const FUNCTION_INTERVAL = 5 * 1000 // 5 segundos em milissegundos

async function checkStatusLoop(showModal: { value: boolean }): Promise<void> {
  const startTime = Date.now()
  // Função que será executada a cada 5 segundos
  async function periodicCheck() {
    const status = await whatsappURLManager(
      (customerServer.value as any).proxy,
      (customerServer.value as any).host,
      (customerServer.value as any).port,
      (customerServer.value as any).key
    ).get(`/session/status/${customerID.value}`)

    if (status.status === 200) {
      clientOnline.value = status.data.success
      status.data.success ? (qrCodeDataURL.value = '') : null //Remove o QR code após a conexão com o bot for bem sucedida
    } else {
      clientOnline.value = 'nodata'
    }
  }

  // Loop principal
  while (showModal.value && Date.now() - startTime <= MAX_EXECUTION_TIME) {
    await periodicCheck()
    await new Promise((resolve) => setTimeout(resolve, FUNCTION_INTERVAL))
  }

  // Fecha o modal após 3 minutos ou se showModal.value se tornar falso
  window.location.reload()
}

// Função para verificação ativa do status do bot

// Função para executar quando o modal for exibido
const onModalShown = async () => {
  qrCodeDataURL.value = ''
  clientOnline.value = 'nodata'
  showModal.value = true
  checkStatusLoop(showModal)
}

const closeModal = () => {
  showModal.value = false
  window.location.reload()
}

// Diretiva Vue.js personalizada para lidar com o evento shown.bs.modal
const vModalShown = {
  mounted(el: HTMLElement) {
    el.addEventListener('shown.bs.modal', onModalShown)
  },
  beforeUnmount(el: HTMLElement) {
    el.removeEventListener('shown.bs.modal', onModalShown)
  }
}
const generateQRCode = async () => {
  try {
    showSpinner.value = true
    const terminateSession = await whatsappURLManager(
      (customerServer.value as any).proxy,
      (customerServer.value as any).host,
      (customerServer.value as any).port,
      (customerServer.value as any).key
    ).get(`/session/terminate/${customerID.value}`)

    if (terminateSession.status != 200) {
      console.log('Erro não tratado... Terminar Sessão')
    }
    const startSession = await whatsappURLManager(
      (customerServer.value as any).proxy,
      (customerServer.value as any).host,
      (customerServer.value as any).port,
      (customerServer.value as any).key
    ).get(`/session/start/${customerID.value}`)
    if (startSession.status != 200) {
      console.log('Erro não tratado... Iniciar Sessão')
    }
    for (let i = 1; i <= 60; i++) {
      await sleep(2000)
      const generateQR = await whatsappURLManager(
        (customerServer.value as any).proxy,
        (customerServer.value as any).host,
        (customerServer.value as any).port,
        (customerServer.value as any).key
      ).get(`/session/qr/${customerID.value}`)
      if (generateQR.status != 200) {
        console.log('Erro não tratado... Gerar QR code')
      }
      if (generateQR.data.success) {
        console.log('Sucesso ao gerar o QR code...')
        qrCodeDataURL.value = await QRCode.toDataURL(generateQR.data.qr)
        showModal.value = true // Exibe o modal após gerar o QR code
        showSpinner.value = false
        break
      } else {
        console.log('QR code não pronto: ', generateQR.data)
      }
    }
  } catch (error) {
    console.error('Error generating QR code:', error)
    showSpinner.value = false
  }
}
</script>

<style scoped></style>
