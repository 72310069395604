<script setup lang="ts">
import { apiClient } from '../api/ServiceApi'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import CreatePlan from '../components/modal/plan/CreatePlan.vue'
import DeletePlan from '../components/modal/plan/DeletePlan.vue'
import EditPlan from '../components/modal/plan/EditPlan.vue'
import { onBeforeMount, ref } from 'vue'
library.add(fas, faTwitter, faFontAwesome)

const planData = ref<{
  id: string
  name: string
  features: string[]
  value: string
  enabled: string
} | null>(null)
const allPlans = ref()
async function list_plan() {
  try {
    const response = await apiClient.get('/plan')
    if (response['data']['statusCode'] == 200) {
      allPlans.value = response['data']['allPlans']
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}
async function populate_model_delete(planName: string, planId: string) {
  let planNameField: HTMLInputElement | null = null
  let planIdField: HTMLHeadingElement | null = null
  planNameField = document.getElementById('plan_to_delete') as HTMLInputElement | null
  planIdField = document.getElementById('plan_id_to_delete') as HTMLHeadingElement | null
  if (planNameField && planIdField) {
    planNameField.value = planName // Define o valor do input
    planIdField.textContent = planId // Define o texto dentro do elemento <h1>
  }
}
async function populate_model_edit(
  planName: string,
  planId: string,
  planFeatures: string[],
  planValue: string,
  planStatus: string
) {
  planData.value = {
    id: planId,
    name: planName,
    features: planFeatures,
    value: planValue,
    enabled: planStatus
  }
}

onBeforeMount(async () => {
  await list_plan()
})
</script>

<template>
  <div class="overflow-auto w-100 table_div">
    <table class="table table-hover">
      <thead class="table-dark">
        <tr>
          <th scope="col" class="user-select-none">Nome</th>
          <th scope="col" class="user-select-none">Funcionalidades</th>
          <th scope="col" class="user-select-none">Valor da mensalidade</th>
          <th scope="col" class="user-select-none">Status</th>
          <th scope="col" class="user-select-none" style="width: 30vh">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="plan in allPlans" v-bind:key="plan.name">
          <td>{{ plan.name }}</td>
          <td>
            <span
              class="badge text-bg-secondary"
              v-for="feature in plan.features"
              v-bind:key="plan.features"
            >
              {{ feature }}
            </span>
          </td>
          <td>{{ plan.value }}</td>
          <td>
            <div v-if="plan.enabled">
              <v-btn data-toggle="tooltip" data-placement="top" title="Plano Ativo">
                <FontAwesomeIcon :icon="fas.faCheck" />
              </v-btn>
            </div>
            <div v-else>
              <v-btn data-toggle="tooltip" data-placement="top" title="Plano Inativo">
                <FontAwesomeIcon :icon="fas.faXmark" />
              </v-btn>
            </div>
          </td>
          <td>
            <v-btn
              class="me-2"
              data-bs-toggle="modal"
              data-bs-target="#editplanmodal"
              @click="
                populate_model_edit(plan.name, plan._id, plan.features, plan.value, plan.enabled)
              "
            >
              <FontAwesomeIcon :icon="fas.faEdit" />
            </v-btn>
            <v-btn
              data-bs-toggle="modal"
              data-bs-target="#deleteplanmodal"
              @click="populate_model_delete(plan.name, plan._id)"
            >
              <FontAwesomeIcon :icon="fas.faTrashAlt" />
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="section p-3">
    <v-btn data-bs-toggle="modal" data-bs-target="#createplanmodal"> Cadastrar Plano </v-btn>
    <CreatePlan />
    <DeletePlan />
    <EditPlan :planData="planData" />
  </div>
</template>

<style scoped>
.table_div {
  max-height: 60vh;
  height: 55vh;
}
.table thead {
  position: sticky;
  top: 0;
  background-color: #b40000; /* Optional: Set a background color for the fixed header */
  z-index: 1; /* Ensure the header is on top of the content */
}
</style>
