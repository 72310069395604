<script setup lang="ts">
import { jwtDecode } from 'jwt-decode'
import router from '@/router'
import { ref } from 'vue'

interface TokenData {
  email: string
  exp: number
  iat: number
  name: string
  sub: string
}
const avatarImage = ref('/avatar.png')
const logo = ref('/logo.png')
const token = localStorage.getItem('token')
const token_data = jwtDecode<TokenData>(String(token))
const isDrawerOpen = ref(true)
const userData = {
  name: token_data.name,
  id: token_data.sub
}

const leadmenu = ref([
  ['Painel', 'mdi-apps', '/admin/leads/panel'],
  ['Extrator de Dados', 'mdi-magnify-minus', '/admin/leads/createspreadsheet']
])

const settingsmenu = ref([
  ['Usuários', 'mdi-account-multiple-outline', '/admin/users'],
  ['Grupos', 'mdi-account-group', '/admin/groups'],
  ['Servidores', 'mdi-server', '/admin/server'],
  ['Sistema', 'mdi-cogs', '/admin/settings']
])

async function UserLogout() {
  localStorage.removeItem('token')
  await router.push('/login')
}

function getComponentProps(Component: any) {
  if (Component.type.__name === 'MarketingMassiveSender') {
    return { userData: userData }
  } else if (Component.type.__name === 'AutomationsView') {
    return { userData: userData }
  }
  return {}
}
</script>

<template>
  <v-app>
    <v-footer class="sidebar-gradient" height="20px" app>
      <v-row justify="center" no-gutters>
        <v-col class="text-center">
          © {{ new Date().getFullYear() }} | <strong>bot ex_</strong> | Versão 2.0
        </v-col>
      </v-row>
    </v-footer>

    <v-app-bar flat class="border-b sidebar-gradient">
      <v-app-bar-nav-icon color="#fff" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>

      <v-app-bar-title>
        <img :src="logo" style="height: 10vh" />
      </v-app-bar-title>
      <template #append>
        <v-chip>
          {{ userData?.name }}
        </v-chip>
        <v-img :width="50" :src="avatarImage" rounded="circle" :title="userData?.name"></v-img>
      </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="isDrawerOpen" class="primary">
      <v-card elevated hover>
        <v-card-title class="text-wrap">
          {{ userData?.name }}
        </v-card-title>
      </v-card>

      <v-list>
        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-view-dashboard"
          title="Dashboard"
          router
          to="/admin/home"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>

        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-account-box-multiple"
          title="Clientes"
          router
          to="/admin/customer"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>
        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-notebook"
          title="Planos"
          router
          to="/admin/plans"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>
        <v-list-group value="Leads">
          <template v-slot:activator="{ props }">
            <v-list-item
              slim
              v-bind="props"
              title="Lead"
              prepend-icon="mdi:mdi-lead-pencil"
            ></v-list-item>
          </template>

          <v-list-item
            slim
            v-for="([title, icon, path], i) in leadmenu"
            :key="i"
            router
            :to="path"
            :prepend-icon="icon"
            :title="title"
            rounded="xl"
            active-class="menu-active"
          >
          </v-list-item>
        </v-list-group>
        <v-list-group value="Configurações">
          <template v-slot:activator="{ props }">
            <v-list-item
              slim
              v-bind="props"
              title="Configurações"
              prepend-icon="mdi:mdi-cog-outline"
            ></v-list-item>
          </template>

          <v-list-item
            slim
            v-for="([title, icon, path], i) in settingsmenu"
            :key="i"
            router
            :to="path"
            :prepend-icon="icon"
            :title="title"
            rounded="xl"
            active-class="menu-active"
          >
          </v-list-item>
        </v-list-group>
        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi-account-circle"
          title="Perfil"
          router
          to="/admin/profile"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>
        <v-list-item></v-list-item>
        <v-list-item></v-list-item>
        <v-divider></v-divider>
        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-information"
          title="Sobre"
          router
          to="/admin/about"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>
        <v-list-item
          slim
          class="v-list-item-link"
          prepend-icon="mdi:mdi-logout"
          title="Logout"
          @click="UserLogout"
          rounded="xl"
          active-class="menu-active"
        >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view v-slot="{ Component, route }">
        <component :is="Component" v-bind="getComponentProps(Component)" />
      </router-view>
    </v-main>
  </v-app>
</template>

<style scoped>
.menu-active {
  color: var(--color-botex);
}
.sidebar-gradient {
  background: linear-gradient(90deg, #5170ff, #ff66c4);
  color: #fff;
}
.text-wrap {
  white-space: normal;
  word-wrap: break-word;
}
</style>
