<script setup lang="ts">
import { apiClient } from '../api/ServiceApi'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { onBeforeMount, ref } from 'vue'

library.add(fas, faTwitter, faFontAwesome)

// Define the types for lead and column
interface Lead {
  _id: string
  name: string
  email: string
  phone: string
  status: string
}

interface Column {
  id: string
  title: string
  cards: Lead[]
}

const allLeads = ref<Lead[]>([])

const kanbanColumns = ref<Column[]>([
  { id: 'new', title: 'Novo', cards: [] },
  { id: 'open', title: 'Em aberto', cards: [] },
  { id: 'inProgress', title: 'Em Progresso', cards: [] },
  { id: 'review', title: 'Em Revisão', cards: [] },
  { id: 'completed', title: 'Concluído', cards: [] }
])

async function listLeads() {
  try {
    const response = await apiClient.get('/lead')
    if (response.data.statusCode == 200) {
      allLeads.value = response.data.allLeads
      // Populate the kanban columns based on lead status
      kanbanColumns.value[0].cards = response.data.allLeads.filter(
        (lead: Lead) => lead.status === 'created'
      )
      kanbanColumns.value[1].cards = response.data.allLeads.filter(
        (lead: Lead) => lead.status === 'open'
      )
      kanbanColumns.value[2].cards = response.data.allLeads.filter(
        (lead: Lead) => lead.status === 'inProgress'
      )
      kanbanColumns.value[3].cards = response.data.allLeads.filter(
        (lead: Lead) => lead.status === 'review'
      )
      kanbanColumns.value[4].cards = response.data.allLeads.filter(
        (lead: Lead) => lead.status === 'completed'
      )
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
    return true
  }
}

onBeforeMount(async () => {
  await listLeads()
})
</script>

<template>
  <div class="kanban-container">
    <div v-for="column in kanbanColumns" :key="column.id" class="kanban-column">
      <h2>{{ column.title }}</h2>
      <div class="kanban-cards">
        <div v-for="card in column.cards" :key="card._id" class="kanban-card">
          <div><FontAwesomeIcon :icon="fas.faUser" /> {{ card.name }}</div>
          <div>{{ card.email }}</div>
          <div><FontAwesomeIcon :icon="fas.faPhone" /> {{ card.phone }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="section p-3">
    <!-- <CreateUser />
    <DeleteUser />
    <EditUser /> -->
  </div>
</template>

<style scoped>
.kanban-container {
  display: flex;
  overflow-x: auto;
  padding: 1rem;
}

.kanban-column {
  flex: 0 0 300px; /* Fixed width for each column */
  padding: 1rem;
  margin-right: 1rem;
  background: #f4f5f7;
  border-radius: 4px;
}

.kanban-cards {
  margin-top: 1rem;
}

.kanban-card {
  background: #fff;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}
.btn,
input,
option,
select,
div,
.kanban-column {
  border-radius: 30px;
}
.btn {
  background-color: var(--color-botex);
  font-weight: bold;
  border: none;
}
</style>
