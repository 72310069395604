<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'
import { ref, watch } from 'vue'
interface Server {
  proxy: boolean
  host: string
  port: number
  key: string
}
const props = defineProps<{
  customerData: {
    id: string
    name: string
    email: string
    cpf_cnpj: string
    phone: string
    plan: string
    server: Server
    enabled: string
  } | null
}>()

const customerID = ref('')
const customerName = ref('')
const customerEmail = ref('')
const customerCpf_cnpj = ref('')
const customerPhone = ref('')
const customerPlan = ref('')
const customerServer = ref<Server | null>(null)
const customerEnabled = ref('')

watch(
  () => props.customerData,
  async (newValue) => {
    if (newValue) {
      customerID.value = newValue.id
      customerName.value = newValue.name
      customerEmail.value = newValue.email
      customerCpf_cnpj.value = newValue.cpf_cnpj
      customerPhone.value = newValue.phone
      customerPlan.value = newValue.plan
      customerServer.value = newValue.server
      customerEnabled.value = newValue.enabled
    }
  }
)

async function delete_customer() {
  const $toast = useToast()
  try {
    const response = await apiClient.delete(`/customer/${customerID.value}`)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Cliente deletado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao deletar o cliente!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao deletar o cliente !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}
</script>
<template>
  <div
    class="modal"
    tabindex="-1"
    id="deletecustomermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Deletar Cliente</h5>
          <v-btn type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></v-btn>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja deletar o cliente:</p>
          <h5>{{ customerName }}</h5>
        </div>
        <div class="modal-footer">
          <v-btn type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</v-btn>
          <v-btn type="button" @click="delete_customer()" class="btn btn-danger">
            Deletar Cliente
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
