<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient, getAPIBaseURL } from '@/api/ServiceApi'
import { ref, watch } from 'vue'

interface AutomationData {
  id: string
  input?: string[]
  response: string
  mediaType?: string
  media?: string
  automationPeriod?: string[]
  automationEnabled?: boolean
  automationCustomer?: string
  automationOrder?: string
  automationDelay?: string
  automationMenus?: { _id: string; menuname: string }[]
  automationIsmenu?: boolean
  automationMenuname?: string
  automationMenulink?: string
  waitforhumaniteration?: boolean
}

const props = defineProps<{
  automationData: AutomationData | null
  existsMainMenu: boolean
}>()

interface RequestBody {
  inputs: string[]
  response: string
  period: string[]
  mediatype: string
  media?: string | null
  order: string
  delay: string
  ismenu?: boolean
  menuname?: string
  menulink?: string
  waitforhumaniteration?: boolean
  enabled: boolean | undefined
}

const automationMenus = ref<{ _id: string; menuname: string }[]>([])
const automationID = ref('')
const automationResponse = ref('')
const automationInput = ref<string[]>([])
const automationMediaType = ref('')
const automationPeriod = ref<string[]>([])
const automationOrder = ref('')
const automationDelay = ref('')
const automationCustomer = ref('')
const automationMedia = ref('')
const automationIsmenu = ref<boolean | undefined>(undefined)
const automationMenuname = ref('')
const automationMenulink = ref('')
const automationWaitforhumaniteration = ref<boolean | undefined>(undefined)
const automationEnabled = ref<boolean | undefined>(undefined)
const selectedFile = ref<File | null>(null)
const filePreview = ref<string | null>(null)
const replyType = ref()

watch(
  () => props.automationData,
  (newValue) => {
    if (newValue) {
      const apiBaseURL = getAPIBaseURL()
      automationID.value = newValue.id
      automationResponse.value = newValue.response
      automationInput.value = newValue.input || []
      automationMediaType.value = newValue.mediaType || ''
      if (newValue.media) {
        filePreview.value = apiBaseURL + '/upload/files/' + newValue.media
      }
      automationMenus.value = newValue.automationMenus || []
      automationMedia.value = newValue.media || ''
      automationPeriod.value = newValue.automationPeriod || []
      automationOrder.value = newValue.automationOrder || ''
      automationDelay.value = newValue.automationDelay || ''
      automationCustomer.value = newValue.automationCustomer || ''
      automationIsmenu.value = newValue.automationIsmenu || undefined
      automationMenuname.value = newValue.automationMenuname || ''
      automationMenulink.value = newValue.automationMenulink || ''
      automationWaitforhumaniteration.value = newValue.waitforhumaniteration || false
      automationEnabled.value = newValue.automationEnabled
      if (newValue.automationIsmenu && newValue.automationMenulink) {
        replyType.value = 'sub'
      }
      if (newValue.automationIsmenu && !newValue.automationMenulink) {
        replyType.value = 'menu'
      }
      if (!newValue.automationIsmenu) {
        replyType.value = 'item'
      }
    }
  }
)

const daysOfWeek = [
  { abbr: '0', full: 'Dom' },
  { abbr: '1', full: 'Seg' },
  { abbr: '2', full: 'Ter' },
  { abbr: '3', full: 'Qua' },
  { abbr: '4', full: 'Qui' },
  { abbr: '5', full: 'Sex' },
  { abbr: '6', full: 'Sáb' }
]

const addInput = () => {
  automationInput.value.push('')
}

const removeInput = (index: number) => {
  automationInput.value.splice(index, 1)
}

const selectAllDays = () => {
  automationPeriod.value = daysOfWeek.map((day) => day.abbr)
}

const handleFileChange = (event: Event) => {
  const input = event.target as HTMLInputElement
  if (input.files && input.files[0]) {
    selectedFile.value = input.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      filePreview.value = e.target?.result as string
    }
    reader.readAsDataURL(selectedFile.value)
  }
}

const removeSelectedFile = () => {
  selectedFile.value = null
  filePreview.value = null
}

async function editAutomation() {
  const $toast = useToast()
  try {
    var media: any
    if (automationMediaType.value === 'text' && automationMedia.value) {
      await apiClient.delete(`/upload/${props.automationData?.media}`)
    }
    if (selectedFile.value) {
      if (props.automationData?.media) {
        await apiClient.delete(`/upload/${props.automationData?.media}`)
      }
      const mediaFormData = new FormData()
      mediaFormData.append('file', selectedFile.value)
      mediaFormData.append('userId', automationCustomer.value) // Adicione outros campos conforme necessário

      // Determinar a rota de upload com base no tipo de mídia
      const uploadRoute = '/upload/media'
      const sendMedia = await apiClient.post(uploadRoute, mediaFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      media = sendMedia.data.filename
    }
    const requestBody: RequestBody = {
      inputs: automationInput.value,
      response: automationResponse.value,
      period: automationPeriod.value.sort(),
      mediatype: automationMediaType.value,
      order: automationOrder.value,
      delay: automationDelay.value,
      enabled: automationEnabled.value,
      ...(automationIsmenu.value !== undefined && { ismenu: automationIsmenu.value }),
      ...(automationMenuname.value !== undefined && { menuname: automationMenuname.value }),
      ...(automationMenulink.value !== '' && { menulink: automationMenulink.value }),
      ...(automationWaitforhumaniteration.value !== undefined && {
        waitforhumaniteration: automationWaitforhumaniteration.value
      })
    }

    // Adiciona a chave 'media' apenas se a variável 'media' não for null
    if (media !== null && media !== undefined) {
      requestBody.media = media
      requestBody.response = '-'
    } else {
      if (automationMediaType.value == 'text') {
        requestBody.media = ''
      }
    }
    if (replyType.value === 'sub' || replyType.value === 'menu') {
      requestBody.ismenu = true
    }
    const response = await apiClient.put(`/automation/${automationID.value}`, requestBody)
    if (response['data']['statusCode'] === 200) {
      $toast.success('Plano editado com sucesso!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao editar a automação!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao editar a automação!', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)

    return true
  }
}
</script>

<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="editautomationmodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar Resposta automática</h5>
          <v-btn
            icon="mdi:mdi-close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <form @submit.prevent="editAutomation">
            <div class="row">
              <div class="col-md-4" v-for="(input, index) in automationInput" :key="index">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter something"
                      v-model="automationInput[index]"
                    />
                    <v-btn
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="removeInput(index)"
                    >
                      X
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mb-3"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Aqui serão adicionadas as mensagens que o cliente deve enviar para obter esta resposta. No caso de um menu, pode ser algo simples como o número '1' para a opção X. Se esta resposta for o menu principal, é possível deixá-la sem uma mensagem de entrada. Dessa forma, qualquer mensagem enviada pelo cliente acionará o bot para exibir este menu. No entanto, somente o menu principal pode ficar sem uma mensagem de entrada; todas as outras opções precisam ter uma mensagem específica de entrada."
            >
              <span class="hover-cursor badge rounded-pill btn-color" @click="addInput">
                Adicionar Entrada
              </span>
            </div>
            <div class="mb-3">
              <label for="mediatype">Tipo de mídia da resposta:</label>
              <select
                class="form-control form-control-sm"
                id="mediatype"
                v-model="automationMediaType"
              >
                <option value="text">Texto</option>
                <option value="pdf">PDF</option>
                <option value="img">Imagem</option>
                <option value="audio">Audio</option>
                <option value="video">Video</option>
              </select>
            </div>
            <div class="mb-3">
              <div v-if="automationMediaType === 'text'">
                <label for="funcionalidades" class="form-label">Resposta:</label>
                <textarea
                  class="form-control"
                  id="funcionalidades"
                  rows="3"
                  placeholder="Aqui a resposta que o bot vai enviar para o cliente"
                  v-model="automationResponse"
                ></textarea>
              </div>
              <div v-if="['pdf', 'img', 'audio', 'video'].includes(automationMediaType)">
                <label for="funcionalidades" class="form-label">Selecione a mídia</label>
                <input
                  type="file"
                  class="form-control"
                  id="funcionalidades"
                  @change="handleFileChange"
                />
                <div
                  v-if="automationMediaType === 'img' && filePreview"
                  class="image-preview-container"
                >
                  <img :src="filePreview" alt="Image Preview" class="image-preview" />
                  <v-btn type="button" class="remove-image-btn" @click="removeSelectedFile">
                    X
                  </v-btn>
                </div>
                <div
                  v-if="automationMediaType === 'audio' && filePreview"
                  class="audio-preview-container"
                >
                  <audio :src="filePreview" controls></audio>
                  <v-btn type="button" class="remove-audio-btn" @click="removeSelectedFile">
                    X
                  </v-btn>
                </div>
              </div>
            </div>
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Nesta seção, você poderá configurar uma determinada resposta para ser enviada apenas em um dia específico. Essa opção é interessante para promoções ou produtos que podem ser ofertados em dias específicos. Caso contrário, o ideal é marcar todos os dias."
            >
              <div class="row">
                <label class="col-md-12 control-label" for="checkboxes">Dias da Semana</label>
              </div>
              <div class="row">
                <div class="form-group">
                  <label
                    v-for="(day, index) in daysOfWeek"
                    :key="index"
                    class="col-md-1 checkbox-inline"
                  >
                    <input
                      type="checkbox"
                      name="checkboxes"
                      :id="'checkboxes-' + index"
                      :value="day.abbr"
                      v-model="automationPeriod"
                    />
                    {{ day.full }}
                  </label>
                  <v-btn type="button" class="btn btn-color" @click="selectAllDays">
                    Todos os Dias
                  </v-btn>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <div
                  class="mb-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Caso o bot precise enviar mais de uma resposta por opção, você pode cadastrar várias respostas com a mesma entrada e definir a ordem de envio delas. Por exemplo, a mensagem com ordem 0 será a primeira, a mensagem com ordem 1 será a segunda, e assim por diante, sempre da menor para a maior ordem. Se todas tiverem a mesma ordem, as mais antigas serão enviadas primeiro e as mais novas por último."
                >
                  <label for="order" class="form-label">Ordem:</label>
                  <input
                    type="number"
                    id="order"
                    class="form-control"
                    v-model="automationOrder"
                    min="0"
                  />
                </div>
                <div
                  class="mb-2 hover-cursor badge rounded-pill bg-secondary text-dark bg-opacity-75"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Com essa opção marcada, após o bot enviar esta mensagem, ele irá parar de responder às mensagens do cliente por um tempo determinado. A partir desse ponto, o atendente assumirá o atendimento."
                >
                  <input
                    type="checkbox"
                    id="releaseforservice"
                    v-model="automationWaitforhumaniteration"
                  />
                  <label for="releaseforservice" class="checkbox-inline">Liberar Atendimento</label>
                </div>
              </div>
              <div class="col-md-2">
                <div
                  class="mb-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="É possível definir um atraso para essa mensagem. Assim, quando o cliente digitar uma opção e for essa com o atraso, aparecerá 'digitando...' ou 'gravando áudio...', evitando o envio de muitas mensagens de uma vez só e tornando o atendimento mais humano."
                >
                  <label for="order" class="form-label">Atraso(Seg)</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="automationDelay"
                    min="0"
                    max="10000"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="mb-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Nesta sessão, você precisa definir o tipo de resposta. Pode ser um menu, onde obrigatoriamente um fluxo de atendimento deve ter um menu principal único. Um menu pode ter vários submenus e várias respostas associadas, tanto para o menu quanto para os submenus."
                >
                  <label class="form-check-label" for="itemDeMenu">Tipo de resposta</label>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="menuPrincipal"
                      value="menu"
                      v-model="replyType"
                      :disabled="existsMainMenu"
                    />
                    <label class="form-check-label" for="menuPrincipal">Menu Principal</label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="subMenu"
                      value="sub"
                      v-model="replyType"
                    />
                    <label class="form-check-label" for="subMenu">SubMenu</label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="itemDeMenu"
                      value="item"
                      v-model="replyType"
                    />
                    <label class="form-check-label" for="itemDeMenu">Item de Menu</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-3">
                  <label v-if="replyType === 'menu'" class="form-check-label" for="itemDeMenu"
                    >Nome para o menu</label
                  >
                  <label v-if="replyType === 'sub'" class="form-check-label" for="itemDeMenu"
                    >Nome para o submenu</label
                  >
                  <input
                    v-if="replyType === 'sub' || replyType === 'menu'"
                    type="text"
                    class="form-control"
                    v-model="automationMenuname"
                    placeholder="Nome"
                  />
                  <label
                    v-if="replyType === 'sub' || replyType === 'item'"
                    class="form-check-label"
                    for="itemDeMenu"
                    >Menu associado</label
                  >
                  <select
                    v-if="replyType === 'sub' || replyType === 'item'"
                    class="form-select"
                    v-model="automationMenulink"
                  >
                    <option disabled value="">Menu associado</option>
                    <option v-for="menu in automationMenus" :key="menu._id" :value="menu._id">
                      {{ menu.menuname }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
            <div
              class="mb-3"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Aqui você pode inativar uma resposta para que ela seja ignorada e não enviada para o cliente. É importante não fazer isso para um menu, pois isso pode impactar na experiência de atendimento do cliente."
            >
              <label for="status" class="form-label">Status:</label>
              <select class="form-select" id="status" v-model="automationEnabled">
                <option value="undefined">Selecione o status</option>
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </select>
            </div>
            <v-btn type="submit" class="btn btn-color">Salvar</v-btn>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-group {
  margin-bottom: 10px;
}
.input-group input {
  border-radius: 10px;
}

.hover-cursor:hover {
  background-color: yellow;
  cursor: pointer;
}

.image-preview-container,
.audio-preview-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Altura fixa para a imagem ou áudio */
  margin-top: 10px;
}

.image-preview {
  max-width: 100%; /* Garante que a imagem não ultrapasse o contêiner */
  max-height: 100%; /* Garante que a imagem não ultrapasse o contêiner */
}

.audio-preview-container audio {
  max-width: 100%; /* Garante que o player de áudio não ultrapasse o contêiner */
}

.remove-image-btn,
.remove-audio-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1; /* Garante que o botão de remoção fique sobre a imagem ou áudio */
}
.btn-color {
  background-color: var(--color-botex);
  color: var(--color-text);
  font-weight: bold;
}
.btn,
input,
option,
select,
div,
textarea,
checkbox {
  border-radius: 30px;
}
</style>
