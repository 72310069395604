<script setup lang="ts">
import { useToast } from 'vue-toast-notification'
import { apiClient } from '@/api/ServiceApi'

const server_data = {
  name: '',
  host: '',
  port: '',
  capacity: '',
  key: '',
  proxy: undefined,
  enabled: undefined
}

async function create_server() {
  const $toast = useToast()
  try {
    const response = await apiClient.post('/server', server_data)
    if (response['data']['statusCode'] == 200) {
      $toast.success('Servidor criado com sucesso !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Falha ao criar o Servidor!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno ao criar Servidor !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)

    return true
  }
}
</script>
<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="createservermodal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <!-- Altera para modal-lg para tornar o modal mais largo -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cadastro de Servidor</h5>
          <v-btn
            icon="mdi:mdi-close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></v-btn>
        </div>
        <div class="modal-body">
          <form @submit.prevent="create_server">
            <div class="row">
              <div class="mb-3">
                <label for="nome" class="form-label">Nome:</label>
                <input
                  type="text"
                  class="form-control"
                  id="nome"
                  placeholder="Digite o nome do servidor"
                  v-model="server_data.name"
                />
              </div>
              <div class="col-md-6">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="host" class="form-label">Host:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="host"
                    placeholder="Digite o host do servidor"
                    v-model="server_data.host"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="port" class="form-label">Porta:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="port"
                    placeholder="Porta da API"
                    v-model="server_data.port"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="capacity" class="form-label">Capacidade de Bots:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="capacity"
                    placeholder="Capacidade de Bots"
                    v-model="server_data.capacity"
                  />
                </div>
              </div>
              <div class="col-md-9">
                <div class="mb-9">
                  <label for="api_key" class="form-label">Chave da API:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="api_key"
                    placeholder="Digite a chave daAPI"
                    v-model="server_data.key"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <!-- Coluna para os campos lado a lado -->
                <div class="mb-3">
                  <label for="status" class="form-label">Proxy:</label>
                  <select class="form-select" id="status" v-model="server_data.proxy">
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div class="mb-3">
                <label for="status" class="form-label">Status:</label>
                <select class="form-select" id="status" v-model="server_data.enabled">
                  <option value="undefined">Selecione o status</option>
                  <option value="true">Ativo</option>
                  <option value="false">Inativo</option>
                </select>
              </div>
              <v-btn type="submit" class="btn btn-primary">Cadastrar Servidor</v-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
