// src/main.ts
import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css' // Certifique-se de que está usando css-loader
import 'vuetify/styles'

// Importação do App e outras dependências
import App from './App.vue'
import router from './router'
import ToastPlugin from 'vue-toast-notification'

// Criação da instância do Vuetify
const vuetify = createVuetify({
  components,
  directives
})

// Criação da instância da aplicação Vue
const app = createApp(App)
// Uso dos plugins necessários
app.use(createPinia())
app.use(ToastPlugin)
app.use(router)
app.use(vuetify)
app.mount('#app')
