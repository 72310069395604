<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { apiClient, whatsappURLManager } from '../api/ServiceApi'
import WhatsAppConnection from '../components/modal/whatsapp/WhatsAppConnection.vue'
import { useDisplay } from 'vuetify'

const { mdAndDown } = useDisplay()
const isMobile = computed(() => mdAndDown.value)

const props = defineProps<{
  userData: {
    id: string
    name: string
  } | null
}>()

const userID = ref('')
const userName = ref('')
const botStatus = ref('')
const connectionName = ref('')
const connectionNumber = ref('')
interface Server {
  proxy: boolean
  host: string
  port: number
  key: string
}
const customerData = ref<{
  id: string
  name: string
  email: string
  cpf_cnpj: string
  login: string
  password: string
  phone: string
  plan: string
  server: Server
  enabled: string
} | null>(null)
watch(
  () => props.userData,
  async (newValue) => {
    if (newValue) {
      userID.value = newValue.id
      userName.value = newValue.name
      await getUserData() // Carrega os dados do usuário após o ID ser definido
      await getAutomationStatus() // Chama a função após os dados do usuário serem carregados
    }
  },
  { immediate: true } // Para executar imediatamente na montagem
)
async function getUserData() {
  try {
    const response = await apiClient.get(`/customer/${userID.value}`)
    if (response['data']['statusCode'] == 200) {
      const data = response.data.Customer
      customerData.value = {
        id: data._id,
        name: data.name,
        email: data.email,
        cpf_cnpj: data.cpf_cnpj,
        login: data.login,
        password: data.password,
        phone: data.phone,
        plan: data.plan,
        server: data.server,
        enabled: data.enabled
      }
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
  }
}
async function getAutomationStatus() {
  if (customerData.value) {
    const server = customerData.value.server
    try {
      const response = await whatsappURLManager(
        server.proxy,
        server.host,
        server.port,
        server.key
      ).get(`/session/status/${userID.value}`)
      botStatus.value = response.data.state // Supondo que a resposta tenha um campo 'status'
      await getConectionIdentify()
    } catch (e) {
      console.log('Erro ao obter status do bot', e)
      botStatus.value = 'DISCONNECTED' // Valor padrão em caso de erro
    }
  } else {
    console.log('Customer data não está disponível')
    botStatus.value = 'DISCONNECTED' // Valor padrão se customerData não estiver disponível
  }
}

async function getConectionIdentify() {
  if (customerData.value) {
    const server = customerData.value.server
    try {
      const response = await whatsappURLManager(
        server.proxy,
        server.host,
        server.port,
        server.key
      ).get(`/client/getClassInfo/${userID.value}`)
      connectionName.value = response.data.sessionInfo.pushname
      connectionNumber.value = response.data.sessionInfo.wid.user
    } catch (e) {
      console.log('Erro ao obter status do bot', e)
    }
  } else {
    console.log('Customer data não está disponível')
  }
}

// Computed property para exibir o status do bot
const botStatusMessage = computed(() => {
  return botStatus.value === 'CONNECTED' ? 'Conectado' : 'Desconectado'
})
// Computed property para a classe do card
const botStatusClass = computed(() => {
  return botStatus.value === 'CONNECTED' ? 'bg-success' : 'bg-error'
})
</script>
<template>
  <div class="p-3">
    <v-card class="text-center rounded p-1" :max-width="isMobile ? '90vw' : '20vw'" elevation="8">
      <template v-slot:title>
        <span class="font-weight-black">Seu assistente virtual está</span>
      </template>

      <v-card-text :class="[botStatusClass, 'pt-4', 'rounded']">
        <h3>{{ botStatusMessage }}</h3>
        <v-btn v-if="botStatusMessage === 'Conectado'" class="ma-2" color="#22ff001e">
          {{ connectionName }} / {{ connectionNumber }}
          <v-icon icon="mdi-whatsapp" end></v-icon>
        </v-btn>
      </v-card-text>
      <v-btn
        width="400"
        class="mt-5"
        large
        color="success"
        data-bs-toggle="modal"
        data-bs-target="#whatsappconnectionmodal"
        v-tooltip="'Conexão com WhatsApp'"
      >
        Conexão com o Whatsapp
      </v-btn>
    </v-card>
  </div>
  <WhatsAppConnection :customerData="customerData" />
</template>

<style scoped></style>
