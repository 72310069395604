<template>
  <div class="p-3">
    <v-row>
      <v-col>
        <v-card
          height="150"
          class="text-center"
          title="Criar envio em massa"
          :subtitle="'Assistente ' + botStatusMessage"
          :class="{ 'blinking-subtitle': botStatusMessage === 'Desconectado' }"
        >
          <v-btn
            v-if="botStatus === 'CONNECTED'"
            icon="mdi-plus-circle"
            size="x-large"
            ref="massiveSendBtn"
            id="massiveSendBtn"
          >
          </v-btn>
          <v-btn
            v-else-if="botStatus !== 'CONNECTED'"
            icon="mdi-plus-circle"
            size="x-large"
            data-bs-toggle="modal"
            data-bs-target="#whatsappconnectionmodal"
          >
          </v-btn>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="150" class="text-center" title="Envios Agendados"
          ><div class="text-h2">{{ scheduleCount }}</div></v-card
        >
      </v-col>
      <v-col>
        <v-card height="150" class="text-center" title="Envios realizados">
          <v-badge class="p-3" :content="successfulCount">
            <v-icon color="success" icon="mdi-check-circle" size="x-large"> </v-icon>
          </v-badge>
          <v-badge class="p-3" :content="failedCount">
            <v-icon color="orange" icon="mdi-alert-circle" size="x-large"></v-icon>
          </v-badge>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <v-table height="70vh" fixed-header>
    <thead>
      <tr>
        <th class="text-left">Status</th>
        <th class="text-left">Mensagem</th>
        <th class="text-left">Informações</th>
        <th class="text-left">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="task in historyTasks" :key="task._id">
        <td>
          <v-icon
            v-if="task.status === 'pending'"
            color="warning"
            icon="mdi-calendar-clock"
          ></v-icon>
          <v-progress-circular
            v-else-if="task.status === 'in-progress'"
            color="red"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else-if="task.status === 'cancelled'" color="error" icon="mdi-cancel"></v-icon>
          <v-icon
            v-else-if="task.status === 'completed'"
            color="success"
            icon="mdi-check-bold"
          ></v-icon>
        </td>
        <td v-if="task.messageType === 'text'">{{ task.messageContent }}</td>
        <td v-else-if="task.messageType === 'img'">
          <v-chip
            download
            target="_blank"
            :href="`${apiBaseURL}/upload/files/${task.messageMedia}`"
            color="primary"
            prepend-icon="mdi-multimedia"
            >Abrir arquivo de imagem</v-chip
          >
        </td>
        <td v-else-if="task.messageType === 'pdf'">
          <v-chip
            download
            target="_blank"
            :href="`${apiBaseURL}/upload/files/${task.messageMedia}`"
            variant="flat"
            prepend-icon="mdi-multimedia"
            >Abrir documento PDF</v-chip
          >
        </td>
        <td v-else-if="task.messageType === 'audio'">
          <v-chip
            download
            target="_blank"
            :href="`${apiBaseURL}/upload/files/${task.messageMedia}`"
            color="red"
            variant="flat"
            prepend-icon="mdi-multimedia"
            >Abrir arquivo de audio</v-chip
          >
        </td>

        <td>
          <v-chip v-if="task.status === 'pending'" color="info"
            >Agendamento: {{ formatDate(task.executionTime) }}
          </v-chip>
          <v-chip color="success">Criação: {{ formatDate(task.createdDate) }}</v-chip>
          <v-chip
            v-if="task.status === 'completed' && task.executionFlag === 'scheduled'"
            color="info"
            >Envio: {{ formatDate(task.executionTime) }}</v-chip
          >
          <v-chip
            v-if="task.status === 'completed' && task.executionFlag === 'scheduled'"
            color="warning"
            >Esse envio foi agendado</v-chip
          >
          <v-chip
            v-if="task.status === 'completed' && task.executionFlag === 'now'"
            color="secondary"
            >Esse envio foi imediato</v-chip
          >
        </td>
        <td>
          <v-btn
            v-if="task.messageType != 'text'"
            prepend-icon="mdi-card-account-phone"
            class="m-1"
            @click="reuseSend(task)"
            color="teal"
          >
            Reutilizar Contatos
          </v-btn>
          <v-btn
            v-else-if="task.messageType === 'text'"
            prepend-icon="mdi-recycle"
            class="m-1"
            @click="reuseSend(task)"
            color="info"
          >
            Reutilizar Envio
          </v-btn>
          <v-btn
            v-if="task.status === 'pending'"
            class="m-1"
            color="error"
            prepend-icon="mdi-timer-off"
            @click="cancelTask(task)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="task.status === 'pending'"
            class="m-1"
            color="success"
            prepend-icon="mdi-invoice-text-fast"
            @click="sendNow(task)"
          >
            Enviar Agora
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-table>
  <!-- <CreateMassiveSend :userData="userData" :reuseData="reuseData" /> -->
  <CreateMassiveSend :userData="userData" :reuseData="reuseData" :activator="massiveSendBtn" />
  <WhatsAppConnection :customerData="customerData" />
</template>
<script setup lang="ts">
import { apiClient, whatsappURLManager, getAPIBaseURL } from '@/api/ServiceApi'
import { ref, watch, computed, onUnmounted } from 'vue'
import { useToast } from 'vue-toast-notification'
import CreateMassiveSend from '@/components/modal/marketing/CreateMassiveSend.vue'
import WhatsAppConnection from '@/components/modal/whatsapp/WhatsAppConnection.vue'

const $toast = useToast()
const massiveSendBtn = ref()
const apiBaseURL = getAPIBaseURL()
type Task = {
  _id: string
  messageContent: string
  status: string
  phoneNumbers: string[]
  executionFlag: string
  createdDate: string
  executionTime: string
  messageType: string
  messageMedia: string
}
const props = defineProps<{
  userData: {
    id: string
    name: string
  } | null
}>()

const reuseData = ref<{
  content: string
  contacts: string
} | null>(null)

const userID = ref('')
const userName = ref('')
const botStatus = ref('')

interface Server {
  proxy: boolean
  host: string
  port: number
  key: string
}
const customerData = ref<{
  id: string
  name: string
  email: string
  cpf_cnpj: string
  login: string
  password: string
  phone: string
  plan: string
  server: Server
  enabled: string
} | null>(null)
watch(
  () => props.userData,
  async (newValue) => {
    if (newValue) {
      userID.value = newValue.id
      userName.value = newValue.name
      await getUserData() // Carrega os dados do usuário após o ID ser definido
      await getAutomationStatus() // Chama a função após os dados do usuário serem carregados
    }
  },
  { immediate: true } // Para executar imediatamente na montagem
)
function formatDate(datetime: string) {
  const date = new Date(datetime)
  const formattedDate = date.toLocaleString('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
  return formattedDate
}
async function getUserData() {
  try {
    const response = await apiClient.get(`/customer/${userID.value}`)
    if (response['data']['statusCode'] == 200) {
      const data = response.data.Customer
      customerData.value = {
        id: data._id,
        name: data.name,
        email: data.email,
        cpf_cnpj: data.cpf_cnpj,
        login: data.login,
        password: data.password,
        phone: data.phone,
        plan: data.plan,
        server: data.server,
        enabled: data.enabled
      }
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
  }
}
async function getAutomationStatus() {
  if (customerData.value) {
    const server = customerData.value.server
    try {
      const response = await whatsappURLManager(
        server.proxy,
        server.host,
        server.port,
        server.key
      ).get(`/session/status/${userID.value}`)
      botStatus.value = response.data.state // Supondo que a resposta tenha um campo 'status'
    } catch (e) {
      console.log('Erro ao obter status do bot', e)
      botStatus.value = 'DISCONNECTED' // Valor padrão em caso de erro
    }
  } else {
    console.log('Customer data não está disponível')
    botStatus.value = 'DISCONNECTED' // Valor padrão se customerData não estiver disponível
  }
}
// Computed property para exibir o status do bot
const botStatusMessage = computed(() => {
  return botStatus.value === 'CONNECTED' ? 'Online' : 'Desconectado'
})
// Computed property para a classe do card
const botStatusClass = computed(() => {
  return botStatus.value === 'CONNECTED' ? 'connected' : 'disconnected'
})
// Computed property para o ícone do bot
const botStatusIcon = computed(() => {
  return botStatus.value === 'CONNECTED' ? 'fa-rocket' : 'fa-face-frown'
})

const allTasks = ref<Task[]>([])

const successfulCount = computed(() => countTasks('completed'))
const failedCount = computed(() => countTasks('failed'))
const scheduleCount = computed(() => countTasks('pending'))
// const historyTasks = computed(() => allTasks.value)
const historyTasks = computed(() => {
  return allTasks.value.sort((a, b) => {
    // Se ambos os itens têm o status "scheduled" e possuem executionTime, ordene por executionTime
    if (a.status === 'scheduled' && b.status === 'scheduled') {
      if (a.executionTime && b.executionTime) {
        return new Date(b.executionTime).getTime() - new Date(a.executionTime).getTime()
      }
      if (a.executionTime && !b.executionTime) {
        return -1
      }
      if (!a.executionTime && b.executionTime) {
        return 1
      }
    }
    // Se somente `a` está agendado com executionTime
    if (a.status === 'scheduled' && a.executionTime) {
      return -1
    }
    // Se somente `b` está agendado com executionTime
    if (b.status === 'scheduled' && b.executionTime) {
      return 1
    }
    // Ordenação padrão por createdDate
    return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
  })
})

async function listSenderHistory(customerID: string) {
  try {
    const response = await apiClient.get(`/tasks/sendersbycustomer/${customerID}`)
    if (response.status === 200) {
      allTasks.value = response.data
    } else {
      console.log('Houve um erro')
    }
  } catch (e) {
    console.log('Erro interno', e)
  }
}

function countTasks(status: string): number {
  return allTasks.value.filter((task) => task.status === status).length
}

function reuseSend(task: Task) {
  const cleanedNumbers = task.phoneNumbers.map((number) => number.replace(/@c\.us|@g\.us/g, ''))

  reuseData.value = {
    content: task.messageContent,
    contacts: cleanedNumbers.join(',')
  }

  const buttonElement = document.getElementById('massiveSendBtn')
  if (buttonElement) {
    buttonElement.click()
  }
}

async function sendNow(task: Task) {
  try {
    const response = await apiClient.post(`/tasks/sendnow/${task._id}`)
    console.log(response)
    if (response.status === 201) {
      $toast.success('Envio em processamento !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Houve um erro na solicitação!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno para proseguir com o envio !', {
      duration: 5000,
      position: 'top-right'
    })
    setTimeout(() => {
      location.reload()
    }, 1000)
    console.log('Erro interno', e)
  }
}
async function cancelTask(task: Task) {
  try {
    const response = await apiClient.post(`/tasks/cancel/${task._id}`)
    console.log(response)
    if (response.status === 201) {
      $toast.warning('Envio cancelado !', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      $toast.error('Houve um erro na solicitação!', { duration: 5000, position: 'top-right' })
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  } catch (e) {
    $toast.error('Erro interno para cancelar o envio !', { duration: 5000, position: 'top-right' })
    setTimeout(() => {
      location.reload()
    }, 1000)
    console.log('Erro interno', e)
  }
}

watch(
  () => props.userData,
  async (newValue) => {
    if (newValue) {
      await listSenderHistory(newValue.id)
    }
  },
  { immediate: true }
)

// Função para atualizar periodicamente os dados
function updateData() {
  getAutomationStatus()
  listSenderHistory(userID.value)
}

// Chama updateData a cada 30 segundos
const interval = setInterval(updateData, 30000)

// Limpa o intervalo quando o componente é desmontado
onUnmounted(() => {
  clearInterval(interval)
})
</script>

<style scoped></style>
