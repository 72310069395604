<template>
  <v-empty-state
    @mousemove="onMousemove"
    :style="{ color: `hsl(${x}, 80%, 50%)` }"
    bgColor="#787878"
    class="movearea"
    headline="Ooops, 404"
    image="/src/assets/drone.svg"
    height="100vh"
    width="100%"
  >
    <h1 class="text-center text-bold">Pàgina não encontrada</h1>
    <h2 class="text-center">O Recurso que está procurando não existe</h2>
    <h3 class="text-center">Redirecionando em {{ timeLeft }}</h3>
  </v-empty-state>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
// Tempo inicial em segundos
const initialTime = 10 // 10 segundos

// Estado reativo para armazenar o tempo restante
const timeLeft = ref(initialTime)

const x = ref(0)

function onMousemove(e: any) {
  x.value = e.clientX
}

const onCountdownFinish = () => {
  router.push('/')
}

// Função para iniciar o countdown
const startCountdown = () => {
  const countdownInterval = setInterval(() => {
    if (timeLeft.value > 0) {
      timeLeft.value--
    } else {
      clearInterval(countdownInterval)
      onCountdownFinish()
    }
  }, 1000) // Atualiza a cada segundo

  // Limpa o intervalo se o componente for desmontado antes de terminar a contagem
  onUnmounted(() => {
    clearInterval(countdownInterval)
  })
}

// Inicia o countdown quando o componente é montado
onMounted(() => {
  startCountdown()
})
</script>

<style scoped>
.movearea {
  transition: 0.3s background-color ease;
}
</style>
